// fundamentals
import React, { useState, useEffect } from 'react';

// components
import Footer from '../../Components/footer';
import '../Home/home.scss';
import './news.scss';
import LazyLoader from '../../Components/lazyLoader';

// api-config
import NewsService from '../../Api_config/Home_page/news';

// images
// import temple1 from '../../Assets/Images/Home_page/temple1.png';
// import PrayIcon from "../../Assets/Images/Home_page/PrayIcon.svg";
// import ambajitemple from '../../Assets/Images/Home_page/linear.png';
import AdvocatePanelNotice from '../../Assets/Images/News/Advocate-Panel-Notice.jpg';
import AmbajiTempleAdvocate from '../../Assets/Images/News/Ambaji-Temple-Advocate-2025.pdf';

import Arasuri from '../../Components/arasuri';

function News() {
  const [newsData, setNewsData] = useState();
  const [loading, setLoading] = useState(true);

  //   api integration
  useEffect(() => {
    // const id = 1;
    NewsService.getAllNews().then((res) => {
      // console.log(res.data);
      setNewsData(res.data.data);
      //   setLoading(false);
      setTimeout(() => {
        setLoading(false);
      }, [3000]);
    });
  }, []);

  return (
    <>
      {!loading ? (
        <div className="main-wrapper">
          {/* banner  */}
          <section className="header-banner temple-banner">
            <div className="container">
              <h1>સમાચાર</h1>
            </div>
          </section>
          {/* ------- */}

          {/* details  */}
          {/* <div className="news">
            <div className="service-list">
              <div className="container">
                <div className="row">
                  {newsData?.map((item) => {
                    return (
                      <div className="col-md-4">
                         <a href={`/news-details/${item.id}`}>
                          <div className="servicebox-hed">
                            <h5> {item.name}</h5>
                          </div>
                        </a>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div> */}

          <div className="news">
            <div className="service-list">
              <div className="container">
                <div className="row align-items-center justify-content-center">
                  <div className="col-xl-10 col-lg-10 col-md-12 col-sm-12 col-12">
                    <div className="servicebox-hed">
                      <div className="row align-items-center">
                        <div className="col-xl-7 col-lg-6 col-md-12 col-sm-12">
                          <h5>
                            Invitation for Senior Advocates at Ambaji Temple For
                            the matter of Temple Renovation Legal Processes.
                          </h5>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-6 col-sm-6 col-6">
                          <div className="btn-group mt-2 mb-2">
                            <a
                              aria-current="page"
                              className="whitebtn"
                              href={AdvocatePanelNotice}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              View Notice
                            </a>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                          <div className="btn-group mt-2 mb-2">
                            <a
                              aria-current="page"
                              className="orangebtn"
                              href={AmbajiTempleAdvocate}
                              target="_blank"
                              rel="noopener noreferrer"
                              download
                            >
                              Download Form
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ------- */}

          <Arasuri />
          <Footer />
        </div>
      ) : (
        <LazyLoader />
      )}
    </>
  );
}

export default News;
