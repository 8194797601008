import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import Footer from '../../../../Components/footer';
import LazyLoader from '../../../../Components/lazyLoader';
import './dailyRoutine.scss';
import Arasuri from '../../../../Components/arasuri';
import Error404 from '../../../../Pages/error404';
const DailyRoutine = () => {
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, [5000]);
  });

  //-----page-close-404-error-logic-p-----
  if (true) {
    return <Error404 />;
  }
  //-----page-close-404-error-logic-p-----

  return (
    <>
      <Helmet>
        <title>
          51 Shaktipeeth Mahotsav Yagna Pooja Booking - Ambaji Temple, Gujarat
        </title>
        <meta charset="UTF-8" />
        <meta
          name="description"
          content="Book 51 Shaktipeeth Mahotsav for the Yagna Pooja at Ambaji Temple during the 51 Shaktipeeth Mahotsav. Participate in this divine event at Ambaji Temple and Gabbar Hill, Gujarat. Reserve your online booking for an unforgettable spiritual experience. Only One Slot Per Devotee is Available Per Day."
        />
        <meta
          name="keywords"
          content="Ambaji Temple, 51 Shaktipeeth Mahotsav, Yagna Pooja Booking, Shaktipeeth Event, Gabbar Hill Pooja, Ambaji Yagna, Gujarat Shaktipeeth, Online Yagna Booking, Ambaji Temple Events, Spiritual Yagna Gujarat, Shaktipeeth Festival, Temple Pooja Booking, Ambaji Temple Events Registration"
        />
        <meta
          name="author"
          content="Ambaji Temple, Banaskatha Gujarat | BinTech Services"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      {!isLoading ? (
        <div className="main-wrapper">
          <section className="header-banner shaktipith-banner">
            <div className="container">
              <div className="header-heading">
                <h3>પ૧ શક્તિપીઠ મહોત્સવ ના કાર્યક્રમો-૨૦૨૫</h3>
                <h3
                  style={{
                    marginBottom: '0'
                  }}
                >
                  (૦૯/૦૨/૨૦૨પ થી ૧૧/૦૨/૨૦૨પ)
                </h3>
              </div>
            </div>
          </section>

          <section className="about-page">
            <div className="container">
              <div className="about-main">
                <div className="templetop">
                  <div className="row">
                    <div className="table-responsive-box">
                      <table width="100%">
                        <tbody>
                          <tr>
                            <td colSpan="2" align="center">
                              <h3>
                                શ્રી ૫૧ શકિતપીઠ પરિક્રમા મહોત્સવ-૨૦૨૫ ના
                                કાર્યક્રમો
                              </h3>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2" align="center">
                              <h4>
                                તા.૦૯/૦૨/૨૦૨પ થી ૧૧/૦૨/૨૦૨પ સુધીના કાર્યક્રમો
                                નીચે મુજબ યોજાશે.
                              </h4>
                            </td>
                          </tr>
                          <tr>
                            <td rowSpan="10" align="center">
                              <strong>
                                ૦૯/ર/ર૦રપ
                                <br />
                                (રવિવાર)
                              </strong>
                            </td>
                            <td style={{ color: '#ae0015' }}>પાલખી યાત્રા </td>
                          </tr>
                          <tr>
                            <td style={{ color: '#ae0015' }}>ઘંટી યાત્રા</td>
                          </tr>
                          <tr>
                            <td style={{ color: '#ae0015' }}>ધજાયાત્રા</td>
                          </tr>
                          <tr>
                            <td>
                              વિવિધ ધાર્મિક સંસ્થાઓના આગેવાનો / ધર્મગુરુઓના
                              આર્શીવચન
                            </td>
                          </tr>
                          <tr>
                            <td>શક્તિપીઠના સંકુલોમાં શક્તિ યાગ(યજ્ઞ)</td>
                          </tr>
                          <tr>
                            <td>ભજન મંડળીઓ ધ્વારા ભજન સત્સંગ</td>
                          </tr>
                          <tr>
                            <td>આનંદ ગરબા</td>
                          </tr>
                          <tr>
                            <td>વિવિધ મંડળો ધ્વારા પરિક્રમા યાત્રા</td>
                          </tr>
                          <tr>
                            <td>સાંસ્કૃતિક કાર્યક્રમ</td>
                          </tr>
                          <tr>
                            <td>
                              ફોરેસ્ટ, પોલીસ, આરોગ્ય વગેરે વિભાગો ધ્વારા યાત્રા
                            </td>
                          </tr>
                          <tr>
                            <td rowSpan="10" align="center">
                              <strong>
                                ૧૦/ર/ર૦રપ
                                <br />
                                (સોમવાર)
                              </strong>
                            </td>
                            <td style={{ color: '#ae0015' }}>પાલખી યાત્રા</td>
                          </tr>
                          <tr>
                            <td style={{ color: '#ae0015' }}>પાદુકા યાત્રા</td>
                          </tr>
                          <tr>
                            <td style={{ color: '#ae0015' }}>ચમાર યાત્રા</td>
                          </tr>
                          <tr>
                            <td>
                              વિવિધ ધાર્મિક સંસ્થાઓના આગેવાનો / ધર્મગુરુઓના
                              આર્શીવચન
                            </td>
                          </tr>
                          <tr>
                            <td>આનંદ ગરબા અખંડ ધુન</td>
                          </tr>
                          <tr>
                            <td>શક્તિપીઠના સંકુલોમાં શક્તિ યાગ(યજ્ઞ) </td>
                          </tr>
                          <tr>
                            <td>ભજન મંડળીઓ ધ્વારા ભજન સત્સંગ </td>
                          </tr>
                          <tr>
                            <td>
                              વિવિધ મંડળો તથા વિવિધ સમાજ ધ્વારા પરિક્રમા યાત્રા
                            </td>
                          </tr>
                          <tr>
                            <td>રાત્રિ પરિક્રમા દર્શન સ્પર્ધા </td>
                          </tr>
                          <tr>
                            <td>સાંસ્કૃતિક કાર્યક્રમ</td>
                          </tr>
                          <tr>
                            <td rowSpan="12" align="center">
                              <strong>
                                ૧૧/ર/ર૦૨પ
                                <br />
                                (મંગળવાર)
                              </strong>
                            </td>
                            <td style={{ color: '#ae0015' }}>પાલખી યાત્રા</td>
                          </tr>
                          <tr>
                            <td style={{ color: '#ae0015' }}>મશાલ યાત્રા</td>
                          </tr>
                          <tr>
                            <td style={{ color: '#ae0015' }}>જ્યોત યાત્રા</td>
                          </tr>
                          <tr>
                            <td style={{ color: '#ae0015' }}>ત્રિશુળ યાત્રા</td>
                          </tr>
                          <tr>
                            <td>
                              વિવિધ ધાર્મિક સંસ્થાઓના આગેવાનો / ધર્મગુરુઓના
                              આર્શીવચન
                            </td>
                          </tr>
                          <tr>
                            <td>શક્તિપીઠના સંકુલોમાં શકિત યાગ(યજ્ઞ)</td>
                          </tr>
                          <tr>
                            <td>મંત્રોત્સવ</td>
                          </tr>
                          <tr>
                            <td>ડ્રોન ધ્વારા પુષ્પવૃષ્ટિ</td>
                          </tr>
                          <tr>
                            <td>ભજન મંડળીઓ ધ્વારા ભજન સત્સંગ</td>
                          </tr>
                          <tr>
                            <td>વિવિધ મંડળો ધ્વારા પરિક્રમા યાત્રા</td>
                          </tr>

                          <tr>
                            <td>સાંસ્કૃતિક કાર્યક્રમ</td>
                          </tr>
                          <tr>
                            <td>ગબ્બર ટોચ ખાતે રાત્રે ૧૨:૦૦ કલાકે આરતી</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Arasuri />
          <Footer />
        </div>
      ) : (
        <LazyLoader />
      )}
    </>
  );
};

export default DailyRoutine;
