import React, { useState, useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import Modal from 'react-modal';
import { Helmet } from 'react-helmet-async';
import Footer from '../../../../Components/footer';
import LazyLoader from '../../../../Components/lazyLoader';
import Arasuri from '../../../../Components/arasuri';
import ZoomIcon from '../../../../Assets/Images/Photo_gallery/ZoomIcon.svg';
import Parking1 from '../../../../Assets/Images/51_shaktipith/51-shaktipeeth-mahotsav-parking/આર.ટી.ઓ. સર્કલ રોડ.jpg';
import Parking2 from '../../../../Assets/Images/51_shaktipith/51-shaktipeeth-mahotsav-parking/નવી કોલેજ ની સામે સિવિલ હોસ્પિટલ.jpg';
import Parking3 from '../../../../Assets/Images/51_shaktipith/51-shaktipeeth-mahotsav-parking/જીએમડીસી ગ્રાઉન્ડ.jpg';
import Parking4 from '../../../../Assets/Images/51_shaktipith/51-shaktipeeth-mahotsav-parking/માંગલ્ય વન જવાના રસ્તે.jpg';
import Error404 from '../../../../Pages/error404';

const ParkingSolution = () => {
  const [isLoading, setLoading] = useState(true);
  const [isModalOpen, setModalOpen] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);

  // const imageData = [
  //   { src: Parking1, caption: 'બસ પાર્કીંગ - A' },
  //   { src: Parking2, caption: 'બસ પાર્કીંગ - B' },
  //   { src: Parking3, caption: 'બસ પાર્કીંગ - C' },
  //   { src: Parking4, caption: 'બસ પાર્કીંગ - D' }
  // ];
  const imageData = [
    { src: Parking1 },
    { src: Parking2 },
    { src: Parking3 },
    { src: Parking4 }
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 5000);
    return () => clearTimeout(timer);
  }, []);

  const openModal = (index) => {
    setActiveSlide(index);
    setModalOpen(true);
  };
  const closeModal = () => setModalOpen(false);

  //-----page-close-404-error-logic-p-----
  if (true) {
    return <Error404 />;
  }
  //-----page-close-404-error-logic-p-----

  return (
    <>
      <Helmet>
        <title>
          51 Shaktipeeth Mahotsav Yagna Pooja Booking - Ambaji Temple, Gujarat
        </title>
        <meta charSet="UTF-8" />
        <meta
          name="description"
          content="Book 51 Shaktipeeth Mahotsav for the Yagna Pooja at Ambaji Temple during the 51 Shaktipeeth Mahotsav. Participate in this divine event at Ambaji Temple and Gabbar Hill, Gujarat. Reserve your online booking for an unforgettable spiritual experience. Only One Slot Per Devotee is Available Per Day."
        />
        <meta
          name="keywords"
          content="Ambaji Temple, 51 Shaktipeeth Mahotsav, Yagna Pooja Booking, Shaktipeeth Event, Gabbar Hill Pooja, Ambaji Yagna, Gujarat Shaktipeeth, Online Yagna Booking, Ambaji Temple Events, Spiritual Yagna Gujarat, Shaktipeeth Festival, Temple Pooja Booking, Ambaji Temple Events Registration"
        />
        <meta
          name="author"
          content="Ambaji Temple, Banaskatha Gujarat | BinTech Services"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      {!isLoading ? (
        <div className="main-wrapper">
          <section className="header-banner shaktipith-banner">
            <div className="container">
              <div className="header-heading">
                <h3>પ૧ શક્તિપીઠ મહોત્સવ પાર્કિંગ સુવિધા-૨૦૨૫</h3>
                <h3 style={{ marginBottom: '0' }}>
                  (૦૯/૦૨/૨૦૨પ થી ૧૧/૦૨/૨૦૨પ)
                </h3>
              </div>
            </div>
          </section>

          <section className="about-page">
            <div className="container">
              <div className="row">
                {imageData.map((image, index) => (
                  <div
                    className="col-xl-3 col-lg-4 col-md-6 col-sm-12"
                    key={index}
                  >
                    <div className="photo-gallery">
                      <div
                        className="photo-img"
                        onClick={() => openModal(index)}
                      >
                        <img
                          style={{ borderRadius: '0' }}
                          src={image.src}
                          alt="Ambaji Temple Banaskatha Gujarat"
                        />
                        <span className="photo-zoomicon">
                          <img src={ZoomIcon} alt="Zoom Icon" />
                        </span>
                      </div>
                      {/* <p>{image.caption}</p> */}
                    </div>
                  </div>
                ))}
                <PhotoModal
                  isModalOpen={isModalOpen}
                  closeModal={closeModal}
                  activeSlide={activeSlide}
                  imageData={imageData}
                />
              </div>

              <div className="about-main pt-5">
                <div className="templetop">
                  <div className="row">
                    <div class="table-responsive-box">
                      <table width="100%" border="1">
                        <tbody>
                          <tr>
                            <td width="25%" rowspan="5">
                              <strong>
                                હંગામી પાર્કિંગ પ્લોટ
                                <br />
                                (ST મારફત આવનાર યાત્રાળુઓ માટે )
                              </strong>
                            </td>
                            <td width="5%">
                              <strong>ક્રમ </strong>
                            </td>
                            <td width="22%">
                              <strong>જીલ્લા </strong>
                            </td>
                            <td width="50%">
                              <strong>વિસ્તારનું નામ </strong>
                            </td>
                          </tr>
                          <tr>
                            <td>A</td>
                            <td>બનાસકાંઠા (ડીસા, કાંકરેજ, ધાનેરા)</td>
                            <td>આર.ટી.ઓ. સર્કલ રોડ </td>
                          </tr>
                          <tr>
                            <td>B</td>
                            <td>બનાસકાંઠા (પાલનપુર, વડગામ, દિયોદર, થરાદ)</td>
                            <td>નવી કોલેજ ની સામે સિવિલ હોસ્પિટલ વિસ્તાર </td>
                          </tr>
                          <tr>
                            <td>C</td>
                            <td>મહેસાણા, પાટણ, સાબરકાંઠા, અરવલ્લી</td>
                            <td>જીએમડીસી ગ્રાઉન્ડ</td>
                          </tr>
                          <tr>
                            <td>D</td>
                            <td>અમદાવાદ, ગાંધીનગર</td>
                            <td>માંગલ્ય વન જવાના રસ્તે (શાંતિ વન) </td>
                          </tr>
                          <tr>
                            <td colspan="4">&nbsp;</td>
                          </tr>
                          <tr>
                            <td rowspan="7">
                              <strong>પાર્કિંગ (ખાનગી વાહનો માટે)</strong>
                            </td>
                            <td colspan="3">સર્વે નંબર ૯૦</td>
                          </tr>
                          <tr>
                            <td colspan="3">જૂની કોલેજ (હડાદ રોડ)</td>
                          </tr>
                          <tr>
                            <td colspan="3">દિવાળીબા ગુરૂ ભવન</td>
                          </tr>
                          <tr>
                            <td colspan="3">શક્તિધ્વારની સામે </td>
                          </tr>
                          <tr>
                            <td colspan="3">કૈલાશ ટેકરી સામે</td>
                          </tr>
                          <tr>
                            <td colspan="3">આપેશ્વર મહાદેવ મંદિર સામે</td>
                          </tr>
                          <tr>
                            <td colspan="3">સવિતા ગોવિંદ સદનની બાજુમાં</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Arasuri />
          <Footer />
        </div>
      ) : (
        <LazyLoader />
      )}
    </>
  );
};

const PhotoModal = ({ isModalOpen, closeModal, activeSlide, imageData }) => {
  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={{
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'transparent',
          border: 'none'
        }
      }}
      shouldCloseOnOverlayClick={true}
      contentLabel="Image Modal"
    >
      <div className="d-flex justify-content-end close-btn">
        <button type="button" className="btn" onClick={closeModal}>
          X
        </button>
      </div>
      <Swiper
        navigation={true}
        modules={[Navigation]}
        initialSlide={activeSlide}
        loop
        className="mySwiper"
      >
        {imageData.map((image, index) => (
          <SwiperSlide key={index}>
            <div className="img-container">
              <img
                src={image.src}
                alt={`Slide ${index + 1}`}
                style={{ display: 'block', margin: 'auto' }}
              />
              {/* <p>
                <b>{image.caption}</b>
              </p> */}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </Modal>
  );
};

export default ParkingSolution;
