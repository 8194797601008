import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaCheck } from 'react-icons/fa';
import './payment-status.scss';
import UtilsService from '../../../Api_config/Home_page/utils';
import OnlinePrasadService from '../../../Api_config/E_store/onlinePrasad';
import { routes } from '../../../_Constants/routes';

const OnlinePrasadSuccess = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [transactionData, setTransactionData] = useState();

  useEffect(() => {
    const id = location?.pathname?.split('/').pop();
    // console.log("Location and ID:", location, Number(id));

    OnlinePrasadService.getByProductId(Number(id))
      .then((res) => {
        if (res.data.success) {
          // console.log(res.data.data[0]);
          setTransactionData(res.data.data[0]);
        }
      })
      .catch((e) => {
        navigate(routes?.error500);
        // console.log(e);
      });
  }, [location]);

  const printPaymentReciept = () => {
    const id = location?.pathname?.split('/').pop();
    navigate(`/online-ambaji-temple-prasad-receipt/${id}`, {
      state: { data: transactionData }
    });
  };

  return (
    <>
      <section className="header-banner money-banner">
        <div className="container">
          <h1>Payment Status</h1>
        </div>
      </section>
      <section className="about-page">
        <div className="container">
          <div className="money-donation-success">
            <div className="check-icon">
              <FaCheck />
            </div>
            <br />
            <h3 className="text-center">Payment Successfull</h3>
            <br />
            <div className="row" style={{ rowGap: '15px' }}>
              <div className="col-5">
                <b>Order Number :</b>
              </div>
              <div className="col-7" style={{ wordBreak: 'break-all' }}>
                {transactionData?.order_number}
              </div>
              {!transactionData?.is_identity_hidden && (
                <>
                  <div className="col-5">
                    <b>Person Name :</b>
                  </div>
                  <div className="col-7">{`${transactionData?.User?.first_name} ${transactionData?.User?.last_name}`}</div>
                  {/* <div className="col-5">
                <b>Email:</b>
              </div>
              <div className="col-7">{transactionData?.User?.email_id}</div> */}
                  <div className="col-5">
                    <b>Contact Number :</b>
                  </div>
                  <div className="col-7">
                    {transactionData?.User?.primary_contact_number}
                  </div>
                </>
              )}
              <div className="col-5">
                <b>Date of Transaction :</b>{' '}
              </div>
              <div className="col-7">
                {transactionData?.order_placed_date &&
                  UtilsService.formatDate(transactionData?.order_placed_date)}
              </div>
              <div className="col-5">
                <b>Amount :</b>
              </div>
              <div className="col-7"> Rs. {transactionData?.total_amount}</div>
              {/* <div className="col-5">
            <b>Donation Type:</b>
          </div>
          <div className="col-7">{transactionData?.DonationCategory?.name}</div> */}
            </div>

            <div className="d-flex justify-content-end mt-4">
              <button
                className="btn btn-success"
                onClick={() => printPaymentReciept()}
              >
                Download
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default OnlinePrasadSuccess;
