import axios from "axios";

export default axios.create({
  
  // baseURL: "https://dev.api.ambajitemple.in.bintech.services/public/api/",
  baseURL: "https://api.ambajitemple.in/public/api/",

  headers: {
    "Content-Type": "application/json",
    // "Content-Type": "multipart/form-data",
  },
});
