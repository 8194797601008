import { api_urls } from '../../_Constants/api_urls';
import http from '../../_environment';

const getShopAll = () => {
  return http.get(
    `${
      api_urls?.getProduct
    }?is_shown_on_website=${true}&product_category_id=${2}`
  );
};

// const getAllPrasad = () => {
//   return http.get(
//     `${api_urls?.getProduct}?is_shown_on_website=${true}&category_id=${1}`
//   );
// };

const getById = (id) => {
  return http.get(`${api_urls?.getProduct}?id=${id}`);
};

const getByIdProductReceipt = (id) => {
  return http.get(`${api_urls?.getOrder}?id=${id}`);
};

const OnlineShopService = {
  getShopAll,
  getById,
  getByIdProductReceipt
};

export default OnlineShopService;
