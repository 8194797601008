import http from '../../_environment';
import { api_urls } from '../../_Constants/api_urls';

const yagnaGetAll = (yagna_date) => {
  // const formattedDate = new Date(yagna_date).toISOString().split('T')[0];
  const [month, day, year] = yagna_date.split('-'); // Split the date into components
  const formattedDate = `${year}-${month}-${day}`;

  return http.get(`${api_urls?.yagnaGetAll}?yagna_date=${formattedDate}`);
};

// const yagnaGetAll = (yagna_date) => {
//   const date = new Date(yagna_date);

//   // Validate the date
//   if (isNaN(date.getTime())) {
//     console.error('Invalid date provided:', yagna_date);
//     return Promise.reject(new Error('Invalid date provided'));
//   }

//   const formattedDate = date.toISOString().split('T')[0];
//   const url = `${api_urls?.yagnaGetAll}?yagna_date=${formattedDate}`;

//   console.log('Formatted Date:', formattedDate); // Log the formatted date
//   console.log('Request URL:', url); // Log the request URL

//   return http.get(url);
// };

const getBookingById = (id) => {
  if (id) {
    return http.get(`${api_urls?.getBookingById}?id=${id}`);
  } else {
    console.error('ID is undefined or invalid');
    return;
  }
};

const onlineYagnaBooking = (requestData) => {
  return http.post(api_urls?.onlineYagnaBooking, requestData);
};
const offlineYagnaBooking = (requestData) => {
  return http.post(api_urls?.offlineYagnaBooking, requestData);
};

const sendOTP = (primary_contact_number, email_ids) => {
  const encodedEmails = encodeURIComponent(JSON.stringify(email_ids)); // Encode email array as a JSON string
  return http.get(
    `${api_urls?.shaktipithYagnaSendOtp}?email_id=${encodedEmails}&contact_number=${primary_contact_number}`
  );
};

// const sendOTP = (primary_contact_number) => {
//   return http.get(
//     `${api_urls?.shaktipithYagnaSendOtp}?contact_number=${primary_contact_number}`
//   );
// };

const verifyOTP = (primary_contact_number, email_id, otp) => {
  return http.get(
    `${api_urls?.shaktipithYagnaVerifyOtp}?contact_number=${primary_contact_number}&email=${email_id}&otp=${otp}`
  );
};

// const verifyOTP = (primary_contact_number, otp) => {
//   return http.get(
//     `${api_urls?.shaktipithYagnaVerifyOtp}?contact_number=${primary_contact_number}&otp=${otp}`
//   );
// };

const ShaktipithYagnaBookingService = {
  yagnaGetAll,
  onlineYagnaBooking,
  offlineYagnaBooking,
  getBookingById,
  sendOTP,
  verifyOTP
};
export default ShaktipithYagnaBookingService;
