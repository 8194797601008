import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './contactUs.scss';
import Footer from '../../Components/footer';
import InquiryService from '../../Api_config/Contact_us/inquiry';
// import { routes } from "../../_Constants/routes";
import { ContactUsSchema } from '../../_Constants/validations';
import AddressIcon from '../../Assets/Images/Contact_us/AddressIcon.svg';
import PhoneIcon from '../../Assets/Images/Contact_us/PhoneIcon.svg';
import MailIcon from '../../Assets/Images/Contact_us/MailIcon.svg';
import Arasuri from '../../Components/arasuri';
import ReactStars from 'react-rating-stars-component';
import LazyLoader from '../../Components/lazyLoader';

const ContactUs = () => {
  const [isLoading, setLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState('');
  const [isShowToster, setShowToster] = useState(false);
  const [inquiryData, setInquiryData] = useState([]);
  const [rating, setRating] = useState([]);
  const [InquiryQueData, setInquiryQueData] = useState([]);
  const [selectedType, setSelectedType] = useState('');
  const [responseData, setResponseData] = useState(null); // Added state for response data
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 5000);

    InquiryService.getAllInquiryType().then((res) => {
      setInquiryData(res.data.data);
    });

    InquiryService.getAllInquiryTypeQue().then((res) => {
      setInquiryQueData(res.data.data);
    });
  }, []);

  const initialValues = {
    name: '',
    email_id: '',
    contact_number: '',
    message: '',
    contact_code_id: '1',
    inquiry_type_id: 0,
    InquiryRatings: []
  };

  // Handler for radio button change
  const handleRadioChange = (event, setFieldValue) => {
    const value = event.target.value;
    setSelectedType(value);
    setFieldValue('inquiry_type_id', parseInt(value));

    // Clear previous ratings if type is not "Rating"
    // if (value !== "3") {
    //   setFieldValue("InquiryRatings", []);
    // }
  };

  // Handler for rating change
  const ratingChanged = (newRating, id, setFieldValue) => {
    let found = false;
    // Traverse the array to find if the id exists
    for (let item of rating) {
      if (item.master_rate_type_id === id) {
        // Update the existing item's rate
        item.rate = newRating.toString();
        found = true;
        break;
      }
    }
    // If the id was not found, add a new object
    if (!found) {
      const newRatingObject = {
        rate: newRating.toString(),
        master_rate_type_id: id
      };
      rating.push(newRatingObject);
    }
  };
  console.log(rating);

  // const onSubmit = (values, { resetForm }) => {
  //   InquiryService.create(values)
  //     .then((res) => {
  //       if (res.data.success) {
  //         // console.log(res.data.data);
  //         setShowToster(true);
  //         setSuccessMessage(res.data.message);
  //         setResponseData(res.data.data); // Set response data here
  //         resetForm();
  //         toast.success(res.data.message, { autoClose: 1000 });
  //       } else {
  //         setSuccessMessage(null);
  //       }
  //     })
  //     .catch((e) => {
  //       navigate(routes.error500);
  //       // console.log(e);
  //     });
  // };

  const onSubmit = (values, { resetForm }) => {
    let formattedValues = values;

    if (values?.inquiry_type_id === 3) {
      formattedValues = {
        ...values,
        InquiryRatings: rating
      };
    }

    InquiryService.create(formattedValues)
      .then((res) => {
        if (res.data.success) {
          setShowToster(true);
          setSuccessMessage(res.data.message);
          setResponseData(res.data.data); // Set response data here
          resetForm();
          toast.success(res.data.message, { autoClose: 1000 });
        } else {
          setSuccessMessage(null);
        }
      })
      .catch((e) => {
        // navigate(routes.error500);
      });
  };

  return (
    <>
      <Helmet>
        <title>Contact Ambaji Temple | Banaskantha Gujarat</title>
        <meta
          name="description"
          content="Managed by Shri Ambaji Mata Devasthan Trust, Ambaji Temple is one of the 51 Shakti
Peethas where the heart of Goddess Sati fell. Experience live darshan, view temple and aarti
timing, donate online, contribute to the Suvarna Shikhar Gold, book Pavdi Puja, Dhaja, and
Yagna through our website. Join us in worship and devotion from anywhere in the world."
        />
        <meta
          name="keywords"
          content="Ambaji Temple, Ambaji Temple queries, Ambaji Temple information, Temple contact details,
Banaskantha temple contact, Religious assistance India, Ambaji Temple, Ambaji Mataji,
Ambaji Gujarat, Ambaji Darshan, Ambaji Temple Timing, Ambaji Temple Gabbar"
        />
        <meta
          name="author"
          content="Ambaji Temple and Gabbar Banaskantha Gujarat | BinTech Services Ahmedabad"
        />
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {!isLoading ? (
        <div className="main-wrapper">
          <section className="header-banner contactus-banner">
            <div className="container">
              <h1>અમારો સંપર્ક કરો</h1>
            </div>
          </section>
          <section className="contact-page">
            <div className="container">
              <div className="contact-wrapper">
                <div className="contact-info">
                  <h2>અમારી સંપર્ક વિગતો</h2>
                  <ul className="location-info">
                    <li>
                      <img src={AddressIcon} alt="Address" />
                      <h3>સરનામું</h3>
                      <a
                        href="https://maps.app.goo.gl/N6LAqcGPTgHu5zy27"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        અંબાજી, તા. દાંતા જિલ્લો: બનાસકાઠા-385110
                      </a>
                    </li>
                    <li>
                      <img src={PhoneIcon} alt="Phone" />
                      <h3>ફોન નંબર</h3>
                      <p>
                        મંદિર નિરીક્ષક કચેરી:{' '}
                        <a href="tel:02749-262236">02749-262236</a>
                      </p>
                      <p>
                        કોન્સ્ટ. બ્રાન્ચ:{' '}
                        <a href="tel:02749-262930">02749-262930</a>
                      </p>
                      <p>
                        ફેક્સ: <a href="#">02749-264536, 262931</a>
                      </p>
                    </li>
                    <li>
                      <img src={MailIcon} alt="Mail" />
                      <h3>ઈમેલ એડ્રેસ</h3>
                      <a href="mailto:info@ambajitemple.in">
                        info@ambajitemple.in
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="contact-form">
                  <Formik
                    initialValues={initialValues}
                    enableReinitialize={true}
                    validationSchema={ContactUsSchema}
                    onSubmit={onSubmit}
                  >
                    {({ values, setFieldValue, isValid, dirty }) => (
                      <Form>
                        <div className="form-group">
                          <label>
                            Name / પૂરું નામ{' '}
                            <span style={{ color: '#ae0015' }}>*</span>
                          </label>
                          <Field
                            type="text"
                            className="form-control"
                            name="name"
                            required
                          />
                          <ErrorMessage
                            name="name"
                            className="red-text"
                            component="div"
                          />
                        </div>
                        <div className="row">
                          <div className="form-group col-12 col-md-6">
                            <label>
                              Mobile No / મોબાઇલ નંબર{' '}
                              <span style={{ color: '#ae0015' }}>*</span>
                            </label>
                            <Field
                              type="text"
                              className="form-control"
                              name="contact_number"
                              required
                              maxLength="10"
                              pattern="\d{10}"
                            />
                            <ErrorMessage
                              name="contact_number"
                              className="red-text"
                              component="div"
                            />
                          </div>

                          <div className="form-group col-12 col-md-6">
                            <label>
                              E-mail Id / ઈમેલ{' '}
                              {/* <span style={{ color: '#ae0015' }}>*</span> */}
                            </label>
                            <Field
                              type="email"
                              className="form-control"
                              name="email_id"
                            />
                            {/* <ErrorMessage
                              name="email_id"
                              className="red-text"
                              component="div"
                            /> */}
                          </div>
                        </div>

                        <div className="form-group">
                          <div className="col-12 col-md-12">
                            <label>
                              Message Type / સંદેશનો પ્રકાર{' '}
                              <span style={{ color: '#ae0015' }}>*</span>
                            </label>
                            <div className="contact-btn-red">
                              {inquiryData?.map((item) => (
                                <span key={item.id}>
                                  <input
                                    type="radio"
                                    className="radio"
                                    value={item.id}
                                    id={item.id}
                                    name="inquiry_type_id"
                                    onChange={(e) =>
                                      handleRadioChange(
                                        e,
                                        setFieldValue,
                                        setRating([])
                                      )
                                    }
                                    required
                                  />{' '}
                                  <label
                                    htmlFor={item.id}
                                    style={{ marginBottom: '0px' }}
                                  >
                                    {item.name}
                                  </label>
                                </span>
                              ))}
                            </div>
                          </div>

                          {selectedType === '3' && (
                            <div className="Rating-div">
                              <div className="row">
                                <div className="col-md-12">
                                  {InquiryQueData?.map((item) => (
                                    <div
                                      className="question-star"
                                      key={item.id}
                                    >
                                      <p>
                                        {item.name}
                                        <ReactStars
                                          count={5}
                                          onChange={(newRating) =>
                                            ratingChanged(
                                              newRating,
                                              item.id,
                                              setFieldValue
                                            )
                                          }
                                          activeColor="#ffd801"
                                          name="InquiryRatings"
                                        />
                                      </p>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </div>
                          )}
                        </div>

                        <div className="form-group">
                          <div className="col-12 col-md-12">
                            <label>Message / સંદેશ</label>
                            <Field
                              as="textarea"
                              className="form-control"
                              name="message"
                              required={selectedType !== '3'}
                            />
                          </div>
                        </div>
                        <div className=" ">
                          <div className="col-12 col-md-12">
                            <div className="text-end">
                              <button
                                type="submit"
                                className="orangebtn"
                                // disabled={!isValid || !dirty}
                              >
                                Send
                              </button>
                            </div>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
              <div className="contactmap">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3635.229706434997!2d72.8261577!3d24.338489799999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395d23ca5f9f0207%3A0x85e50801cc904278!2sShree%20Arasuri%20Ambaji%20Gabbar%20Tirtha!5e0!3m2!1sen!2sin!4v1707204636080!5m2!1sen!2sin"
                  width="100%"
                  height="400px"
                  allowFullScreen
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe>
              </div>
            </div>
          </section>

          {isShowToster ? (
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
            />
          ) : null}
          <Arasuri />
          <Footer />
        </div>
      ) : (
        <LazyLoader />
      )}
    </>
  );
};

export default ContactUs;
