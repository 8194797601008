import React, { useEffect, useState } from 'react';
import { FaTimes } from 'react-icons/fa';
import Modal from 'react-modal';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import UtilsService from '../../../Api_config/Home_page/utils';
import ProfileService from '../../../Api_config/Profile/userProfile';
import { AddressSchema } from '../../../_Constants/validations';
import '../../../App.css';
import AddressDetailsService from '../../../Api_config/Profile/addressDetails';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../../_Constants/routes';
import './addressDetails.scss';
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)'
  }
};

const AddressDetails = () => {
  const navigate = useNavigate();
  const [addressList, setAddressList] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [countryList, setCountryList] = useState([]);
  const [provinceList, setProvinceList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [zipcodeList, setZipcodeList] = useState([]);
  const [defaultAddress, setDefaultAddress] = useState(false);
  const [ModalOpen, setIsModalOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const STATIC_COUNTRY_ID = 1; // Static country ID
  const STATIC_PROVINCE_ID = 11; // Static province ID

  // COUNTRY AND ADDRESS ALL DATA
  useEffect(() => {
    // console.log("address");
    UtilsService.getCountry().then(
      (res) => {
        if (res.data.success) {
          setCountryList(res.data.data);
        }
      },
      (error) => {
        // console.log(error);
      }
    );
    if (localStorage.getItem('user_id')) {
      AddressDetailsService.getAddress()
        .then((res) => {
          if (res.data.success) {
            setAddressList(res.data.data);
          }
        })
        .catch((error) => {
          navigate(routes?.error500);
          // console.log(error);
        });
    }
  }, [setCountryList]);

  useEffect(() => {
    getCity(); // Automatically load cities for province_id = 1
  }, []);

  // FORMIK BINDING WITH ONSUBMIT FUNCTION
  const formik = useFormik({
    initialValues: {
      first_name: '',
      last_name: '',
      primary_contact_number: '',
      primary_contact_code_id: 1,
      address_type: '',
      address_line_1: '',
      address_line_2: '',
      country_id: STATIC_COUNTRY_ID,
      province_id: STATIC_PROVINCE_ID,
      city_id: '',
      zipcode_id: '',
      user_id: ''
    },
    validationSchema: AddressSchema,
    validateOnChange: true,
    validateOnBlur: true,
    enableReinitialize: true,
    onSubmit: (values) => {
      Object.assign(values, {
        user_id: localStorage.getItem('user_id')
        // user_id: JSON.parse(localStorage.getItem("user_id")),
      });
      // console.log(values);
      AddressDetailsService.saveAddress(values)
        .then((res) => {
          if (res.data.success) {
            toast.success(res.data.message);
            AddressDetailsService.getAddress()
              .then((res) => {
                if (res.data.success) {
                  setAddressList(res.data.data);
                }
              })
              .catch((error) => {
                navigate(routes?.error500);
                // console.log(error);
              });
            if (defaultAddress) {
              ProfileService.getUserData().then(
                (res) => {
                  if (res.data.success) {
                    const requestData = res.data.data[0];
                    Object.assign(requestData, {
                      address_type: values?.address_type,
                      address_line_1: values?.address_line_1,
                      address_line_2: values?.address_line_2,
                      country_id: values?.country_id,
                      province_id: values?.province_id,
                      city_id: values?.city_id,
                      zipcode_id: values?.zipcode_id
                    });
                    ProfileService.updateUser(requestData).then(
                      (res) => {
                        if (res.data.success) {
                          // console.log(res.data.data);
                        }
                      },
                      (error) => {
                        // console.log(error);
                      }
                    );
                  }
                },
                (error) => {
                  // console.log(error);
                }
              );
            }
          }
        })
        .catch((error) => {
          navigate(routes?.error500);
          toast.error(error.response.data.message);
        });
      setModalOpen(false);
    }
  });

  // GET PROVINCE API INTEGRATION
  // const getProvince = (event) => {
  //   UtilsService.getProvince(event?.target?.value ?? event)
  //     .then((res) => {
  //       if (res.data.success) {
  //         setProvinceList(res.data.data);
  //       }
  //     })
  //     .catch((error) => {
  //       navigate(routes?.error500);
  //       // console.log(error);
  //     });
  // };

  // Use static country_id
  const getProvince = () => {
    UtilsService.getProvince(STATIC_COUNTRY_ID)
      .then((res) => {
        if (res.data.success) {
          setProvinceList(res.data.data); // Set provinces based on static country_id
          formik.setFieldValue('province_id', STATIC_PROVINCE_ID); // Set default province_id as 1
          getCity(STATIC_PROVINCE_ID); // Automatically load cities for province_id = 1
        }
      })
      .catch((error) => {
        navigate(routes?.error500);
        console.log(error);
      });
  };

  // GET CITY API INTEGRATION
  // const getCity = (event) => {
  //   UtilsService.getCity(event?.target?.value ?? event)
  //     .then((res) => {
  //       if (res.data.success) {
  //         setCityList(res.data.data);
  //       }
  //     })
  //     .catch((error) => {
  //       navigate(routes?.error500);
  //       // console.log(error)
  //     });
  // };
  const getCity = () => {
    UtilsService.getCity(STATIC_PROVINCE_ID) // Always use province_id = 1
      .then((res) => {
        console.log('API Response:', res.data); // Log the response data for debugging
        if (res.data.success) {
          setCityList(res.data.data);
        } else {
          console.log('Error: No cities found for this province');
          setCityList([]); // Set an empty array if no cities found
        }
      })
      .catch((error) => {
        navigate(routes?.error500);
        console.log('Error fetching cities:', error); // Log the error in case of failure
      });
  };

  // GET ZIPCODE BY CITY API INTEGRATION

  const getZipcodeByCityDeliverable = (event) => {
    UtilsService.getZipcodeByCityDeliverable(event?.target?.value ?? event)
      .then((res) => {
        if (res.data.success) {
          setZipcodeList(res.data.data);
        }
      })
      .catch((error) => {
        navigate(routes?.error500);
        // console.log(error);
      });
  };

  // DELETE BY ID
  const onDelete = (id) => {
    AddressDetailsService.deleteAddress(id)
      .then((res) => {
        if (res.data.success) {
          toast.success(res.data.message);

          AddressDetailsService.getAddress()
            .then((res) => {
              if (res.data.success) {
                setAddressList(res.data.data);
              }
            })
            .catch((error) => {
              navigate(routes?.error500);
              // console.log(error);
            });
        }
      })
      .catch((error) => {
        navigate(routes?.error500);

        // console.log(error);
      });
  };

  const handleDeleteClick = (id) => {
    setSelectedId(id);
    setIsModalOpen(true);
  };

  const confirmDelete = () => {
    if (selectedId) {
      onDelete(selectedId); // Call delete function
    }
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="address-details">
        <div className="d-flex justify-content-end">
          <button
            className="orangebtn mb-3"
            onClick={() => [setModalOpen(true), formik?.resetForm()]}
          >
            Add New
          </button>
        </div>
        <div className="row" style={{ alignItems: 'stretch' }}>
          {addressList?.length > 0 ? (
            <>
              {addressList?.map((item, i) => {
                return (
                  <div className="col-12 col-md-6 col-lg-4 mb-2" key={i}>
                    <div className="p-2 card" style={{ minHeight: '100%' }}>
                      <div
                        className="d-flex justify-content-between"
                        style={{ flexDirection: 'column', gap: '5px' }}
                      >
                        <div>
                          <h6 className="mb-2">
                            {item?.first_name} {item?.last_name}
                          </h6>
                          <p className="mb-0">{item?.address_line_1},</p>
                          <p className="mb-0">{item?.address_line_2}</p>
                          <p className="mb-0">
                            {item?.City?.name}, {item?.Province?.name} -{' '}
                            {item?.ZipCode?.name}
                          </p>
                        </div>
                        <div>
                          <div className="d-flex justify-content-between mt-2">
                            {/* <button
                              className="btn btn-outline-danger"
                              onClick={() => onDelete(item?.id)}
                            >
                              Delete
                            </button> */}
                            <button
                              className="btn btn-outline-danger "
                              onClick={() => handleDeleteClick(item?.id)}
                            >
                              Delete
                            </button>
                            <button
                              className="btn btn-outline-success"
                              onClick={() => [
                                setModalOpen(true),
                                formik?.setValues(item),
                                getProvince(STATIC_COUNTRY_ID),
                                // getProvince(item?.country_id),
                                getCity(STATIC_PROVINCE_ID),
                                // getCity(item?.province_id),
                                getZipcodeByCityDeliverable(item?.city_id)
                              ]}
                            >
                              Edit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

              {/* Confirmation Modal for delete address*/}
              {/* Full-screen Confirmation Modal */}
              {ModalOpen && (
                <div className="delete-confirmation-modal">
                  <div className="modal-content">
                    <div className="card">
                      <div className="card-body">
                        <h4 className="text-center">Delete Confirmation</h4>
                        <p className="text-center">
                          Are you sure you want to delete this address?
                        </p>
                      </div>
                      <div className="card-footer d-flex justify-content-between">
                        <button
                          type="button"
                          className="whitebtn"
                          onClick={() => setIsModalOpen(false)}
                        >
                          No
                        </button>
                        <button
                          type="button"
                          className="orangebtn"
                          onClick={confirmDelete}
                        >
                          Yes
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <p className="red-text text-center">No Address Found</p>
          )}
        </div>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={() => setModalOpen(false)}
          style={customStyles}
          shouldCloseOnOverlayClick={false}
          contentLabel="Example Modal"
        >
          <button
            className="btn btn-default float-end"
            onClick={() => setModalOpen(false)}
          >
            <FaTimes />
          </button>
          <br />
          <br />
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-12 form-group">
                <input
                  type="checkbox"
                  name="is_default_address"
                  id="is_default_address"
                  onChange={() => setDefaultAddress(true)}
                />
                <label htmlFor="is_default_address" className="ms-2">
                  Set as default address
                </label>
                <br />
                <label className="text-red"> * Fields are Required</label>
              </div>
              <div className="col-12 col-md-6 form-group">
                <label htmlFor="first_name">
                  First Name<span className="text-red">*</span>
                </label>
                <input
                  type="text"
                  name="first_name"
                  placeholder="First Name"
                  className="form-control"
                  value={formik?.values?.first_name}
                  onChange={formik.handleChange}
                />
                {formik?.touched?.first_name && formik?.errors?.first_name && (
                  <p className="red-text">{formik?.errors?.first_name}</p>
                )}
              </div>
              <div className="col-12 col-md-6 form-group">
                <label htmlFor="last_name">
                  Last Name<span className="text-red">*</span>
                </label>
                <input
                  type="text"
                  name="last_name"
                  placeholder="Last Name"
                  className="form-control"
                  value={formik?.values?.last_name}
                  onChange={formik.handleChange}
                />
                {formik?.touched?.last_name && formik?.errors?.last_name && (
                  <p className="red-text">{formik?.errors?.last_name}</p>
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-4 col-md-4 form-group">
                <label htmlFor="primary_contact_code_id">
                  Country Code<span className="text-red">*</span>
                </label>
                <select
                  type="text"
                  name="primary_contact_code_id"
                  className="form-control"
                  value={formik?.values?.primary_contact_code_id}
                  onChange={formik.handleChange}
                >
                  <option value={0} selected disabled>
                    Select Country Code
                  </option>
                  {countryList?.map((item, i) => {
                    return (
                      <option value={item?.id} key={i}>
                        +{item?.phone_code}
                      </option>
                    );
                  })}
                </select>
                {formik?.touched?.primary_contact_code_id &&
                  formik?.errors?.primary_contact_code_id && (
                    <p className="red-text">
                      {formik?.errors?.primary_contact_code_id}
                    </p>
                  )}
              </div>
              <div className="col-8 col-md-8 form-group">
                <label htmlFor="primary_contact_number">
                  Contact Number<span className="text-red">*</span>
                </label>
                <input
                  type="text"
                  minLength={10}
                  maxLength={10}
                  placeholder="Contact Number"
                  name="primary_contact_number"
                  className="form-control"
                  value={formik?.values?.primary_contact_number}
                  onChange={formik.handleChange}
                />
                {formik?.touched?.primary_contact_number &&
                  formik?.errors?.primary_contact_number && (
                    <p className="red-text">
                      {formik?.errors?.primary_contact_number}
                    </p>
                  )}
              </div>
            </div>

            <div className="col-sm-12">
              <div className="form-group">
                <label>
                  Address Type<span className="text-red">*</span>
                </label>
                <div
                  className="d-flex"
                  style={{ alignItems: 'center', gap: '20px' }}
                >
                  <label>
                    <input
                      type="radio"
                      name="address_type"
                      value="1"
                      checked={formik.values.address_type === '1'}
                      onChange={formik.handleChange}
                    />{' '}
                    Home
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="address_type"
                      value="2"
                      checked={formik.values.address_type === '2'}
                      onChange={formik.handleChange}
                    />{' '}
                    Office
                  </label>
                  <label>
                    <input
                      type="radio"
                      name="address_type"
                      value="3"
                      checked={formik.values.address_type === '3'}
                      onChange={formik.handleChange}
                    />{' '}
                    Other
                  </label>
                </div>
                {formik.touched.address_type && formik.errors.address_type && (
                  <p className="red-text">{formik.errors.address_type}</p>
                )}
              </div>
            </div>

            {/* <div className="form-group">
              <label htmlFor="address_type">
                Address Type<span className="text-red">*</span>
              </label>
              <input
                type="text"
                
                name="address_type"
                className="form-control"
                placeholder="Home / Office /Others"
                value={formik?.values?.address_type}
                onChange={formik.handleChange}
              />
              {formik?.touched?.address_type &&
                formik?.errors?.address_type && (
                  <p className="red-text">{formik?.errors?.address_type}</p>
                )}
            </div> */}
            <div className="form-group">
              <label htmlFor="address_line_1">
                Address Line 1<span className="text-red">*</span>
                <small>(House no / Floor / Building / Flat Name)</small>
              </label>
              <input
                type="text"
                placeholder="House no / Floor / Building / Flat Name"
                name="address_line_1"
                className="form-control"
                value={formik?.values?.address_line_1}
                onChange={formik.handleChange}
              />
              {formik?.touched?.address_line_1 &&
                formik?.errors?.address_line_1 && (
                  <p className="red-text">{formik?.errors?.address_line_1}</p>
                )}
            </div>
            <div className="form-group">
              <label htmlFor="address_line_2">
                Address Line 2
                <small>(Area / Sector / Locality / Landmark)</small>
              </label>
              <input
                type="text"
                name="address_line_2"
                placeholder="Area / Sector / Locality / Landmark"
                className="form-control"
                value={formik?.values?.address_line_2}
                onChange={formik.handleChange}
              />
            </div>
            <div className="row">
              {/* <div className="form-group col-12 col-lg-6"> */}
              {/* <label htmlFor="country_id">
                  Country<span className="text-red">*</span>
                </label> */}
              <input
                type="hidden"
                name="country_id"
                className="form-control"
                // value={formik?.values?.country_id}
                value={STATIC_COUNTRY_ID} // Use the static value here
                // onChange={(event) => [
                //   formik.setFieldValue('country_id', event.target.value),
                //   getProvince(event)
                // ]}
                onChange={(event) => [
                  formik.setFieldValue('country_id', STATIC_COUNTRY_ID),
                  getProvince() // Fetch provinces for the selected country
                ]}
                disabled
              />
              {/* <option value={STATIC_COUNTRY_ID}>India</option> */}
              {/* <option value="" selected disabled>
                    Select Country
                  </option>
                  {countryList?.map((item, i) => {
                    return <option value={item?.id}>{item?.name}</option>;
                  })} */}
              {/* </input> */}
              {/* {formik?.touched?.country_id && formik?.errors?.country_id && (
                  <p className="red-text">{formik?.errors?.country_id}</p>
                )} */}
              {/* </div> */}
              {/* <div className="form-group col-12 col-lg-6"> */}
              {/* <label htmlFor="province_id">
                  State<span className="text-red">*</span>
                </label> */}
              <input
                type="hidden"
                name="province_id"
                className="form-control"
                value={STATIC_PROVINCE_ID} // Use the static value here
                // value={formik?.values?.province_id}
                // onChange={(event) => [
                //   formik.setFieldValue('province_id', event.target.value),
                //   getCity(event)
                // ]}
                onChange={(event) => [
                  formik.setFieldValue('province_id', STATIC_PROVINCE_ID),
                  getCity() // Fetch cities for the selected province
                ]}
                disabled
              />
              {/* <option value={STATIC_PROVINCE_ID}>Gujarat</option> */}

              {/* <option value="" selected disabled>
                    Select State
                  </option>
                  {provinceList?.map((item, i) => {
                    return <option value={item?.id}>{item?.name}</option>;
                  })} */}
              {/* </select> */}
              {/* {formik?.touched?.province_id &&
                  formik?.errors?.province_id && (
                    <p className="red-text">{formik?.errors?.province_id}</p>
                  )} */}
              {/* </div> */}
            </div>
            <div className="row">
              <label className="text-red">
                <b>
                  Note: Currently, we are accepting and delivering order in
                  Cities and Pincode Under Gujarat State only.
                </b>{' '}
              </label>
            </div>
            <br />

            <div className="row">
              {/* <div className="form-group col-12 col-lg-6">
                <label htmlFor="city_id">
                  City<span className="text-red">*</span>
                </label>
                <select
                  name="city_id"
                  className="form-control"
                  value={formik?.values?.city_id}
                  onChange={(event) => [
                    formik.setFieldValue('city_id', event.target.value),
                    getZipcodeByCityDeliverable(event)
                  ]}
                  
                >
                  <option value="" selected disabled>
                    Select City
                  </option>
                  {cityList?.map((item, i) => {
                    return <option value={item?.id}>{item?.name}</option>;
                  })}
                </select>
                {formik?.touched?.city_id && formik?.errors?.city_id && (
                  <p className="red-text">{formik?.errors?.city_id}</p>
                )}
              </div> */}
              <div className="form-group col-12 col-lg-12">
                <label htmlFor="city_id">
                  City<span className="text-red">*</span>
                </label>
                <select
                  name="city_id"
                  className="form-control"
                  value={formik?.values?.city_id}
                  onChange={(event) => {
                    formik.setFieldValue('city_id', event.target.value);
                    getZipcodeByCityDeliverable(event); // Fetch zipcode when city changes
                  }}
                >
                  <option value="" selected disabled>
                    Select City
                  </option>
                  {cityList?.length > 0 ? (
                    cityList.map((item) => (
                      <option key={item.id} value={item.id}>
                        {item.name}
                      </option>
                    ))
                  ) : (
                    <option value="" disabled>
                      No cities available
                    </option>
                  )}
                </select>
                {formik?.touched?.city_id && formik?.errors?.city_id && (
                  <p className="red-text">{formik?.errors?.city_id}</p>
                )}
              </div>

              <div className="form-group col-12 col-lg-12">
                <label htmlFor="zipcode_id">
                  Zipcode<span className="text-red">*</span>{' '}
                  <small>(Below listed zipcodes are deliverable)</small>
                </label>
                <select
                  name="zipcode_id"
                  className="form-control"
                  value={formik?.values?.zipcode_id || ''}
                  onChange={formik.handleChange}
                >
                  <option value="" selected disabled>
                    Select Zipcode
                  </option>
                  {zipcodeList?.map((item, i) => {
                    return (
                      <option key={item?.id} value={item?.id}>
                        {item?.name}
                      </option>
                    );
                  })}
                </select>
                {formik?.touched?.zipcode_id && formik?.errors?.zipcode_id && (
                  <p className="red-text">{formik?.errors?.zipcode_id}</p>
                )}
              </div>
            </div>
            <div className="form-footer d-flex justify-content-end">
              <button type="submit" className="orangebtn">
                {formik?.values?.id ? 'Update' : 'Add'}
              </button>
            </div>
          </form>
        </Modal>
      </div>
    </>
  );
};
export default AddressDetails;
