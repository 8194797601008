import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import LazyLoader from '../../../../../Components/lazyLoader';
import { useLocation, useNavigate } from 'react-router-dom';
import ShaktipithYagnaBookingService from '../../../../../Api_config/51_shaktipith_yagna-booking/51ShaktipithYagnaBooking';
import { payu_api_url, payu_key } from '../../../../../_Constants/api_urls';
import Footer from '../../../../../Components/footer';
import Arasuri from '../../../../../Components/arasuri';
import { Field, ErrorMessage } from 'formik';
import { routes } from '../../../../../_Constants/routes';
import { toast } from 'react-toastify';
import './yagnaBookingForm.scss';
import Error404 from '../../../../../Pages/error404';

const ShaktipithYagnaBookingForm = () => {
  const [isLoading, setLoading] = useState(true);
  const [isProcessing, setProcessing] = useState(false);

  const [isCircularLoading, setIsCircularLoading] = useState(false);
  const [payUData, setPayUData] = useState();
  const myButtonRef = useRef(null);
  const location = useLocation();
  const { description, yagna, date, yagna_temple_id } = location.state || {}; // Safely access state
  console.log('Received Data:', { yagna, date, yagna_temple_id });

  const [isIdentityHidden, setIdentityHidden] = useState('');
  const [otp, setOTP] = useState('');
  const [isOTPSend, setOTPSend] = useState(false);
  const [seconds, setSeconds] = useState(30);
  const [isOTPVerified, setOTPVerified] = useState(false);

  const [formData, setFormData] = useState({
    yagna_category_id: 0, // Predefined or fetched dynamically
    yagna_date: date,
    first_name: '',
    last_name: '',
    email_id: '',
    primary_contact_code_id: 1, // Static value for now
    primary_contact_number: '',
    address: '',
    payment_mode: '', // Online or Offline
    // aadhar_number: '',
    // identity_proof: [], // File field
    receipt_number: ''
  });

  const navigate = useNavigate();

  console.log('Date:', date);
  console.log('Selected Yagna:', yagna);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);

  useEffect(() => {
    if (date) {
      // Format to YYYY-MM-DD
      const [month, day, year] = date.split('-'); // Split the date into components
      const formattedDate = `${year}-${month}-${day}`;
      setFormData((prevData) => ({
        ...prevData,
        yagna_date: formattedDate
      }));
    }
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, [date]);

  //   const handleChange = (e) => {
  //     const { name, value } = e.target;
  //     let formattedValue = value;

  //     if (name === 'yagna_date') {
  //       formattedValue = new Date(value).toISOString().split('T')[0];
  //     }

  //     setFormData((prevData) => ({
  //       ...prevData,
  //       [name]: formattedValue,
  //       yagna_temple_id: yagna?.id || 0
  //     }));
  //   };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'payment_mode') {
      setIdentityHidden(value === 'cash' ? 'show' : 'hide');
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
      yagna_temple_id: yagna?.yagna_temple_id || 0
    }));
  };

  /**
   * Send OTP
   * @param {*} primary_contact_number
   */
  const sendOTP = (primary_contact_number, email_id) => {
    if (isOTPSend) setOTP('');
    setIsCircularLoading(true); // Start the loader before sending OTP

    ShaktipithYagnaBookingService.sendOTP(primary_contact_number, email_id)
      .then((res) => {
        if (res.data.success) {
          setOTPSend(true);
          setSeconds(30);
        }
      })
      .catch((error) => {
        navigate(routes?.error500);
        toast.error(error.response?.data?.message, { autoClose: 1500 });
      })
      .finally(() => {
        setIsCircularLoading(false); // Stop the loader once the API call is complete
      });
  };

  /**
   * Verify OTP
   * @param {*} OTP
   * @param {*} email_id
   */
  const verifyOTP = (primary_contact_number, email_id) => {
    ShaktipithYagnaBookingService.verifyOTP(
      primary_contact_number,
      email_id,
      otp
    )
      .then((res) => {
        if (res.data.success) {
          setOTPVerified(true);
        }
      })
      .catch((error) => {
        navigate(routes?.error500);
        toast.error(error.response?.data?.message, { autoClose: 1500 });
      });
  };

  const handleSubmit = async (e) => {
    setProcessing(true);

    e.preventDefault();

    // Validate if payment mode is selected
    if (!formData.payment_mode) {
      alert('Please select a payment mode.');
      return;
    }

    // Validate OTP for offline payment mode
    if (formData.payment_mode === 'offline' && (!otp || otp.length !== 6)) {
      alert('Please enter a valid OTP for cash payment.');
      return;
    }

    const requestData = new FormData();
    requestData.append('yagna_category_id', formData.yagna_category_id);
    requestData.append('yagna_date', formData.yagna_date);
    requestData.append('first_name', formData.first_name);
    requestData.append('last_name', formData.last_name);
    requestData.append('email_id', formData.email_id);
    requestData.append(
      'primary_contact_number',
      parseInt(formData.primary_contact_number, 10)
    );
    requestData.append('address', formData.address);
    requestData.append('payment_mode', formData.payment_mode);
    requestData.append('yagna_temple_id', yagna?.yagna_temple_id || 0);

    // Append receipt number only for offline payment and OTP verification
    if (formData.payment_mode === 'offline' && isOTPVerified) {
      requestData.append('receipt_number', formData.receipt_number);
    }

    let bookingPromise;
    // Set booking promise based on payment mode
    if (formData.payment_mode === 'online') {
      bookingPromise =
        ShaktipithYagnaBookingService.onlineYagnaBooking(requestData);
    } else if (formData.payment_mode === 'offline') {
      bookingPromise =
        ShaktipithYagnaBookingService.offlineYagnaBooking(requestData);
    }

    // Handling the booking promise
    bookingPromise
      .then((response) => {
        setTimeout(() => {
          // Check if the booking was successful
          if (response.data.success) {
            const payUResponse = response.data.data; // Extract payment-related data
            console.log('PayU Response:', payUResponse);

            // Update state with payment data for online payment
            setPayUData({
              ...payUResponse,
              firstname: formData.first_name,
              lastname: formData.last_name
            });

            toast.success('Yagna Booking Created successfully');

            if (formData.payment_mode === 'online') {
              // Process online payment if payment mode is online
              if (
                payUResponse?.txnid &&
                payUResponse?.amount &&
                payUResponse?.hash
              ) {
                console.log('Valid Payment Data:', payUResponse);

                setTimeout(() => {
                  if (myButtonRef.current) {
                    myButtonRef.current.click(); // Trigger online payment
                  }
                }, 200);
              } else {
                toast.error('Payment data is incomplete. Please try again.');
              }
            }
          } else {
            // toast.error('Booking was not successful. Please try again.');
            // toast.success('Yagna Booking Created successfully');
          }

          // Extract the booking ID from the response (assuming it's in response.data.data.id)
          const id = response?.data?.data?.id;
          console.log('Response from API:', response); // Log the full response object
          console.log('Booking ID:', id);

          // Redirect to success page if the payment is offline
          if (formData.payment_mode === 'offline') {
            navigate(`/51-shaktipeeth-yagna-booking-success/${id}`, {
              replace: true
            });
          }
          console.log('Response from API:', response); // Log the full response object
        }, 1000); // Wait for 1 second before processing the response
      })
      .catch((error) => {
        setProcessing(false);

        // Handle errors gracefully
        console.error('Error during booking:', error);
        const errorMessage =
          error.response?.data?.message || 'Booking failed. Please try again.';
        toast.error(errorMessage);
      })
      .finally(() => {
        console.log('done');
        // setIsLoading(false); // Optionally stop loading state
      });
  };

  const handleFileChange = (e) => {
    const allowedTypes = ['image/jpeg', 'image/png', 'application/pdf'];
    const files = Array.from(e.target.files);

    const validFiles = files.filter((file) => {
      if (!allowedTypes.includes(file.type)) {
        alert(`${file.name} is not a valid file type.`);
        return false;
      }
      if (file.size > 5 * 1024 * 1024) {
        alert(`${file.name} exceeds the size limit of 5MB.`);
        return false;
      }
      return true;
    });

    setFormData((prevData) => ({
      ...prevData,
      identity_proof: validFiles
    }));

    console.log('Selected Files:', validFiles);
  };
  const handleOTPChange = (e) => {
    setOTP(e.target.value);
  };

  //-----page-close-404-error-logic-p-----
  if (true) {
    return <Error404 />;
  }
  //-----page-close-404-error-logic-p-----

  return (
    <>
      <Helmet>
        <title>
          51 Shaktipeeth Mahotsav Yagna Pooja Booking - Ambaji Temple, Gujarat
        </title>
        <meta charset="UTF-8" />
        <meta
          name="description"
          content="Book 51 Shaktipeeth Mahotsav for the Yagna Pooja at Ambaji Temple during the 51 Shaktipeeth Mahotsav. Participate in this divine event at Ambaji Temple and Gabbar Hill, Gujarat. Reserve your online booking for an unforgettable spiritual experience. Only One Slot Per Devotee is Available Per Day."
        />
        <meta
          name="keywords"
          content="Ambaji Temple, 51 Shaktipeeth Mahotsav, Yagna Pooja Booking, Shaktipeeth Event, Gabbar Hill Pooja, Ambaji Yagna, Gujarat Shaktipeeth, Online Yagna Booking, Ambaji Temple Events, Spiritual Yagna Gujarat, Shaktipeeth Festival, Temple Pooja Booking, Ambaji Temple Events Registration"
        />
        <meta
          name="author"
          content="Ambaji Temple, Banaskatha Gujarat | BinTech Services"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {!isLoading ? (
        <div className="booking">
          <section className="header-banner yagna-booking">
            <div className="container">
              <div className="header-heading">
                <h3>51 Shaktipeeth Mahotsav-2025</h3>
                <h3>09/02/2025 - 11/02/2025</h3>
              </div>
            </div>
          </section>

          <section className="about-page">
            <div className="container">
              <div className="row guidelines">
                <h4>
                  <span style={{ color: '#ae0015' }}>Date Selected : </span>
                  <span>
                    {date
                      ? new Date(date).toLocaleDateString('en-GB') // Format date to DD-MM-YYYY
                      : 'No date selected'}
                  </span>
                  &nbsp;
                  <span style={{ color: '#ae0015' }}> Yagna Selected : </span>
                  <span>
                    {yagna?.name || 'No Yagna selected'} , {yagna?.description}
                  </span>
                  &nbsp;
                </h4>
              </div>
              <div
                className="card"
                style={{ padding: '20px', border: '1px solid #ccc' }}
              >
                <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>
                  Yagna Booking Form
                </h2>
                {/* <form
                  onSubmit={
                    formData.payment_mode === 'offline'
                      ? handleOfflineSubmit
                      : handleOnlineSubmit
                  }
                > */}
                <form onSubmit={handleSubmit}>
                  {/* Form fields */}
                  <div className="row">
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="first_name">First Name</label>
                        <input
                          type="text"
                          id="first_name"
                          name="first_name"
                          className="form-control"
                          value={formData.first_name}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="last_name">Last Name</label>
                        <input
                          type="text"
                          id="last_name"
                          name="last_name"
                          className="form-control"
                          value={formData.last_name}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="email_id">Email ID</label>
                        <input
                          type="email"
                          id="email_id"
                          name="email_id"
                          className="form-control"
                          value={formData.email_id}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="primary_contact_number">
                          Mobile Number
                        </label>
                        <input
                          type="tel"
                          id="primary_contact_number"
                          name="primary_contact_number"
                          className="form-control"
                          value={formData.primary_contact_number}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="address">Address</label>
                        <input
                          type="text"
                          id="address"
                          name="address"
                          className="form-control"
                          value={formData.address}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    {/* <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="aadhar_number">Aadhar Number</label>
                        <input
                          type="text"
                          id="aadhar_number"
                          name="aadhar_number"
                          className="form-control"
                          value={formData.aadhar_number}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                      <div className="form-group">
                        <label htmlFor="identity_proof">Identity Proof</label>
                        <input
                          type="file"
                          id="identity_proof"
                          name="identity_proof"
                          className="form-control"
                          onChange={(e) => handleFileChange(e)}
                          multiple // Allow multiple file uploads if needed
                        />
                      </div>
                    </div> */}
                    {/* <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                      <div className="form-group">
                        <label>Payment Mode</label>
                        <div>
                          <div
                            className="col-sm-12"
                            style={{ marginRight: '20px' }}
                          >
                            <input
                              type="radio"
                              id="paymentOnline"
                              name="payment_mode"
                              value="online"
                              checked={formData.payment_mode === 'online'}
                              onChange={handleChange}
                            />{' '}
                            <label htmlFor="paymentOnline">Online</label>
                          </div>

                          <div className="col-sm-12">
                            <input
                              type="radio"
                              id="paymentOffline"
                              name="payment_mode"
                              value="offline"
                              checked={formData.payment_mode === 'offline'}
                              onChange={handleChange}
                            />{' '}
                            <label htmlFor="paymentOffline">
                              Cash (Can Only Be Booked at Temple Office)
                            </label>
                          </div>
                        </div>
                      </div>
                    </div> */}

                    <div className="form-group">
                      <label>Mode of Payment</label>
                      <div>
                        <div className="form-check">
                          <input
                            type="radio"
                            id="paymentOnline"
                            name="payment_mode"
                            value="online"
                            checked={formData.payment_mode === 'online'}
                            onChange={handleChange}
                            className="form-check-input"
                          />
                          <label
                            htmlFor="paymentOnline"
                            className="form-check-label"
                          >
                            Online
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            type="radio"
                            id="paymentOffline"
                            name="payment_mode"
                            value="offline"
                            checked={formData.payment_mode === 'offline'}
                            onChange={(e) => {
                              handleChange(e);
                              setOTPSend(false); // Reset OTP when switching to offline
                            }}
                            className="form-check-input"
                          />
                          <label
                            htmlFor="paymentOffline"
                            className="form-check-label"
                          >
                            Cash (Can Only Be Booked at Temple Office)
                          </label>
                        </div>
                      </div>
                    </div>

                    {/* Show OTP button and input field only if "Cash" is selected */}
                    {formData.payment_mode === 'offline' && (
                      <div className="form-group">
                        {!isOTPSend ? (
                          //  <button
                          // className="btn orangebtn"

                          <button
                            className="btn orangebtn"
                            onClick={() =>
                              sendOTP('9726835073', [
                                'charmi@bintech.services',
                                'malhar@bintech.services'
                              ])
                            }
                            //  onClick={() =>
                            //   sendOTP('8799600890', [
                            //     'ambajitempletrust@gmail.com',
                            //     'templeinspector.saamdt.1@gmail.com',
                            //     'malhar@bintech.services'
                            //   ])
                            // }
                            disabled={isCircularLoading}
                          >
                            {/* Get OTP */}
                            {isCircularLoading ? (
                              <div className="loader-container">
                                <div className="spinner"></div>
                              </div>
                            ) : (
                              'Get OTP'
                            )}
                          </button>
                        ) : (
                          <>
                            {isOTPSend && !isOTPVerified && (
                              <>
                                <div className="form-group col-12 col-lg-2">
                                  <label htmlFor="otp">OTP</label>
                                  <input
                                    type="text"
                                    inputMode="numeric"
                                    className="form-control"
                                    // min="6"
                                    // max="6"
                                    name="otp"
                                    placeholder="- - - - - -"
                                    onKeyUp={(event) =>
                                      setOTP(event.target?.value)
                                    }
                                  />
                                </div>
                                <button
                                  type="button"
                                  className="btn orangebtn"
                                  onClick={() =>
                                    verifyOTP(
                                      '9723816597',
                                      'malhar@bintech.services'
                                    )
                                  }
                                  // onClick={() =>
                                  //   verifyOTP(
                                  //     '8799600890',
                                  //     [
                                  //       'ambajitempletrust@gmail.com',
                                  //       'templeinspector.saamdt.1@gmail.com',
                                  //       'malhar@bintech.services'
                                  //     ]
                                  //   )
                                  // }
                                >
                                  Verify OTP
                                </button>
                              </>
                            )}
                            {isOTPVerified && (
                              <div className="row">
                                <div className="form-group col-12 col-md-6 col-lg-4">
                                  <label htmlFor="receipt_number">
                                    Payment Receipt Number(Made at Temple)
                                  </label>
                                  <input
                                    type="text"
                                    id="receipt_number"
                                    name="receipt_number"
                                    className="form-control"
                                    value={formData.receipt_number}
                                    onChange={handleChange}
                                  />
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    )}

                    <div
                      className="form-footer mt-2"
                      style={{ textAlign: 'left' }}
                    >
                      <button
                        className="orangebtn"
                        type="submit"
                        style={{ width: '100%' }}
                        disabled={isProcessing}
                      >
                        {/* Donate Money */}
                        {isProcessing
                          ? 'Processing...'
                          : 'Save and make Payment'}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </section>

          <Arasuri />
          <Footer />
        </div>
      ) : (
        <LazyLoader />
      )}

      {/* PAYMENT GATEWAY FORM  */}
      <form method="POST" action={payu_api_url}>
        <input type="hidden" name="key" value={payu_key} />
        <input type="hidden" name="txnid" value={payUData?.txnid} />
        <input type="hidden" name=" amount" value={payUData?.amount} />
        <input type="hidden" name=" firstname" value={payUData?.firstname} />
        <input type="hidden" name="lastname" value={payUData?.lastname} />
        <input type="hidden" name="email" value={payUData?.email} />
        <input type="hidden" name="productinfo" value={payUData?.productinfo} />
        <input type="hidden" name="phone" value="9198672387" />
        <input type="hidden" name="surl" value={payUData?.surl} />
        <input type="hidden" name="furl" value={payUData?.furl} />
        <input type="hidden" name="curl" value={payUData?.curl} />
        <input type="hidden" name="hash" value={payUData?.hash} />
        <input type="hidden" name="udf1" value={payUData?.udf1} />
        <input type="hidden" name="udf2" value={payUData?.udf2} />
        <input type="hidden" name="udf3" value={payUData?.udf3} />
        <input type="hidden" name="udf4" value={payUData?.udf4} />
        <input type="hidden" name="udf5" value={payUData?.udf5} />
        <button hidden={true} ref={myButtonRef}>
          Submit
        </button>
      </form>
    </>
  );
};

export default ShaktipithYagnaBookingForm;

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (!formData.payment_mode) {
//       alert('Please select a payment mode.');
//       return;
//     }

//     const formattedDate = new Date(formData.yagna_date)
//       .toISOString()
//       .split('T')[0];

//     const requestData = new FormData();

//     // Ensure yagna_temple_id is passed as an integer
//     requestData.append(
//       'yagna_temple_id',
//       parseInt(formData.yagna_temple_id, 10) // Convert to integer
//     );

//     // Add other form data
//     requestData.append('yagna_date', formattedDate);
//     requestData.append('first_name', formData.first_name);
//     requestData.append('last_name', formData.last_name);
//     requestData.append('email_id', formData.email_id);

//     // Ensure primary_contact_number is passed as a number
//     requestData.append(
//       'primary_contact_number',
//       parseInt(formData.primary_contact_number, 10) // Convert to number
//     );

//     requestData.append('address', formData.address);
//     requestData.append('aadhar_number', formData.aadhar_number); // No need for conversion here as it's string
//     requestData.append('payment_mode', formData.payment_mode);

//     // Ensure identity_proof is passed as an array (even if empty)
//     requestData.append(
//       'identity_proof',
//       JSON.stringify(formData.identity_proof)
//     );

//     // Debugging FormData contents
//     console.log('Logging FormData content:');
//     for (let [key, value] of requestData.entries()) {
//       console.log(`${key}:`, value);
//     }

//     try {
//       let response;
//       if (formData.payment_mode === 'online') {
//         response = await ShaktipithYagnaBookingService.onlineYagnaBooking(
//           requestData
//         );
//       } else if (formData.payment_mode === 'offline') {
//         response = await ShaktipithYagnaBookingService.offlineYagnaBooking(
//           requestData
//         );
//       }

//       if (response.status === 201) {
//         // Assuming the response contains the booking ID
//         const id = response.data?.id; // Replace with the correct ID from the response
//         alert('Booking successful!');
//         navigate(`/51-shaktipeeth-yagna-booking-success/${id}`); // Include the ID in the URL
//       }
//     } catch (error) {
//       console.error('Error during booking:', error);
//       alert(
//         error.response?.data?.message || 'Booking failed. Please try again.'
//       );
//     }
//   };
