// import LogOutConfirmationModal from "../Modules/Auth/LogOutConfirmationModal";
// import Cart from "../Modules/E_store/Cart/onlineShoppingCart";

export const routes = {
  // Navbar
  home: '/home',
  liveStream: '/ambaji-temple-gabbar-live-darshan',
  temple: '/about-ambaji-temple',
  gabbar: '/about-ambaji-gabbar',
  contactUs: '/contact-us',

  // 51 shakipith
  shakipith: '/51-shaktipeeth',
  shaktipithMahotsav: '/51-shaktipeeth-mahotsav',
  shaktipithYagnaBookingTermsConditions:
    '/51-shaktipeeth-yagna-booking-terms-and-conditions',
  shaktipithYagnaBooking: '/51-shaktipeeth-yagna-booking',
  shaktipithYagnaBookingForm: '/51-shaktipeeth-yagna-booking-form',
  shaktipithYagnaBookingSuccess: '/51-shaktipeeth-yagna-booking-success/:id',
  shaktipithYagnaBookingFailed: '/51-shaktipeeth-yagna-booking-failed',
  shaktipithYagnaBookingReceipt: '/51-shaktipeeth-yagna-booking-receipt/:id',
  dailyRoutine: '/daily-routine',
  bhojanLocation: '/bhojan-location',
  parkingSolution: '/51-shaktipeeth-parikrama-mahotsav-parking',
  parikramaSang: '/parikrama-sang',
  gabbarTaletiSang: '/gabbar-taleti-sang',

  // Auth
  login: '/login',
  signUp: '/signUp',
  forgotPassword: '/forgot-password',
  profile: '/user-profile',
  userProfile: '/profile',
  OTPverification: '/OTP-verification',

  // Cmponents
  otpVerification: '/otp-verification',

  // Online services
  onlineServices: '/ambaji-temple-online-booking-services',
  onlineShopping: '/online-ambaji-temple-shop',
  // onlineShoppingDetail: "/online-shopping-detail/",
  onlineShoppingDetail: '/online-shopping-detail/:productName/:id',
  mandirYagnaBooking: '/mandir-yagna-booking',

  // ---bhadarviPoonam
  // bhadarviPoonam: '/bhadarvi-poonam',
  // bhadarvipoonamreceiptdownload: '/bhadarvi-poonam-receipt-download',
  // bhadarvipoonamlocationhadad: 'bhadarvi-poonam-hadad-route-location',
  // bhadarvipoonamlocationdanta: 'bhadarvi-poonam-danta-route-location',
  // bhadarvipoonamlocationbhojanalaya: 'bhadarvi-poonam-bhojanalaya-location',
  // bhadarvipoonamlocationcontrolroom: 'bhadarvi-poonam-control-room-location',
  // bhadarvipoonamlocationrestshelter: 'bhadarvi-poonam-rest-shelter-location',
  // bhadarvipoonamtoiletlocation: 'bhadarvi-poonam-toilet-location',
  // bhadarvipoonamdrinkingwaterlocation:
  //   'bhadarvi-poonam-drinking-water-location',
  // bhadarvipoonamlocationhealthcenter: 'bhadarvi-poonam-location-health-center',
  // bhadarvipoonamlocationprasadcounter:
  //   'bhadarvi-poonam-location-prasad-counter',
  // bhadarvipoonamlocationbusstation: 'bhadarvi-poonam-location-bus-station',

  // bhadarvipoonamdonation: 'bhadarvi-poonam-donation',
  // bhadarvipoonamdonationsuccess: 'bhadarvi-poonam-donation-success/:id',

  // bhadarvipoonamdonationreceipt: '/bhadarvi-poonam-donation-receipt/:id',

  // sevaTermsConditions: '/seva-terms-conditions',
  // sevaCamp: '/seva-camp',
  // sevaBookingReceipt: '/seva-camp-booking-receipt/:id',

  // sangTermsConditions: '/sang-terms-conditions',
  // sang: '/sang',
  // sanghBookingReceipt: '/sangh-booking-receipt/:id',

  // search: '/bhadarvi-search',

  // employeVehiclePass: '/employee-vehicle-pass',
  // empVehiclePassSuccess: '/employee-vehicle-pass/registration/success/:id',

  // sthanikVehiclePass: '/ambaji-sthanik-vehicle-pass',
  // sthanikVehiclePassSuccess:
  //   '/ambaji-sthanik-vehicle-pass/registration/success/:id',
  // searchSthanikVehiclePass: '/search-ambaji-sthanik-vehicle-pass',
  // searchSthanikVehiclePassSuccess:
  //   '/search-ambaji-sthanik-vehicle-pass/registration/success/:id',
  // Dhaja Bokking
  dhajaBooking: '/dhaja-booking',
  dhajaBookingReceipt: '/dhaja-booking-receipt/:id',

  // -------donation-------
  donationMoney: '/donation-money',
  donationMoneyReceipt: '/donation-money-receipt/:id',
  donationCounterMoneyReceipt: '/donation-counter-money-receipt/:id',
  donationArticleReceipt: '/donation-article-receipt/:id',

  donationGold: '/donation-gold',
  donationGoldReceipt: '/donation-gold-receipt/:id',
  donationCounterGoldReceipt: '/donation-counter-gold-receipt/:id',
  moneyDonationSuccess: '/money-donation/payment/success/:id',
  goldDonationSuccess: '/gold-donation/payment/success/:id',
  moneyDonationFalied: '/payment/failed',
  // printDonationReceipt: "/print-payment-receipt",

  // e-store
  onlinePrasad: '/online-ambaji-temple-prasad',
  onlinePrasadSuccess: '/online-ambaji-temple-prasad/payment/success/:id',
  onlineProductSuccess: '/online-ambaji-temple-product/payment/success/:id',
  onlinePrasadFailed: '/payment/failed',
  onlinePrasadReceipt: '/online-ambaji-temple-prasad-receipt/:id',
  onlineProductReceipt: '/online-ambaji-temple-product-receipt/:id',
  onlinePrasadDetail: '/online-ambaji-temple-prasad-detail/:prasad_name/:id',
  // orderPrasadReceipt: "/online-ambaji-website-prasad-receipt/:id",
  counterPrasadReceipt: '/counter-ambaji-temple-prasad-receipt/:id',

  // Cart
  Cart: '/cart',

  // Checkout
  checkout: '/checkout',
  checkoutShop: '/checkout-shop',

  // footer
  aboutUs: '/ambaji-temple-trust',
  privacyPolicy: '/privacy-policy',
  termsAndConditions: '/terms-and-conditions',
  shippingAndDelivery: '/shipping-and-delivery',
  news: '/news',
  newsDetails: '/news-details/:id',
  touristAttraction: '/tourist-attraction',
  touristAttractionDetails: '/tourist-attraction-details',

  yagnaBooking: '/51-shaktipeeth-parikrama-yagna-booking',
  shaktiDarshanam: '/shakti-darshanam',
  ShaktiDarshanamAddress: '/shakti-darshanam-address',

  photoGallery: '/photo-gallery',
  // SwayamSevak
  SwayamSevak: '/swayam-sevak-registration',
  swayamSevakReceipt: '/swayam-sevak-receipt/:id',

  // error page

  // error404: "/error404",
  error500: '/error500'
};
