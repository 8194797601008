import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router';
import './donationGold.scss';
import Modal from 'react-modal';
import { useFormik } from 'formik';
import Select from 'react-select';
import { toast } from 'react-toastify';
import GoldDonationService from '../../../Api_config/Donations/goldDonation';
import UtilsService from '../../../Api_config/Home_page/utils';
import { goldDonationSchema } from '../../../_Constants/validations';

import temple1 from '../../../Assets/Images/Temple/temple1.png';
import ambajiTemple2 from '../../../Assets/Images/Temple/ambaji-temple2.jpg';
import ambajiTemple3 from '../../../Assets/Images/Temple/ambaji-temple3.jpg';
import ambajitemple from '../../../Assets/Images/Home_page/linear.png';
// import ambajiTemple22 from "../../../assets/images/ambaji-temple22.jpg";
// import processimg from "../../../assets/images/processimg.jpg";
import Footer from '../../../Components/footer';
import Arasuri from '../../../Components/arasuri';
import LazyLoader from '../../../Components/lazyLoader';
import { routes } from '../../../_Constants/routes';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    padding: '20px '
  }
};

const DonationGold = (props) => {
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, [5000]);
  });
  const { data } = props;
  const [isNumberRegistered, setNumberRegistered] = useState(false);
  const [userData, setUserData] = useState();
  const [userAddress, setUserAddress] = useState();
  const [cityList, setCityList] = useState([]);
  const [provinceList, setProvinceList] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [zipcodeList, setZipcodeList] = useState([]);
  const [phoneCodeList, setPhoneCodeList] = useState([]);
  const [selectedCity, setSelectedCity] = useState();
  const [isIdentityHidden, setIdentityHidden] = useState('show');
  const [isShowForm, setShowForm] = useState(false);
  const [isNumberEditable, setNumberEditable] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [otp, setOTP] = useState();
  const [seconds, setSeconds] = useState(30);
  const [isOTPSend, setOTPSend] = useState(false);
  const [isProcessing, setProcessing] = useState(false);
  const [isResend, setResend] = useState(false);
  const navigate = useNavigate();
  const validate = (values) => {
    const errors = {};

    if (String(values.amount).trim() !== '' && Number(values.amount) <= 0) {
      errors.amount = 'Amount should be more than 0.';
    }
    return errors;
  };
  // MASTER'S USEEFFECT

  useEffect(() => {
    // UtilsService.getAllCity()
    //   .then((res) => {
    //     let cityList = res.data.data.map(function (city) {
    //       return { value: city.id, label: city.name };
    //     });
    //     // console.log(cityList);
    //     setCityList(cityList);
    //   })
    //   .catch((e) => {
    //     navigate(routes?.error500);
    //     // console.log(e);
    //   });

    UtilsService.getCountry().then(
      (res) => {
        if (res.data.success) {
          setCountryList(res.data.data);
          setLoading(false);
          setPhoneCodeList(res.data.data);
        }
      },
      (error) => {
        // console.log(error);
      }
    );

    // UtilsService.getAllProvince()
    //   .then((res) => {
    //     let provinceList = res.data.data.map(function (province) {
    //       return { value: province.id, label: province.name };
    //     });
    //     // console.log(provinceList);
    //     setProvinceList(provinceList);
    //   })
    //   .catch((e) => {
    //     navigate(routes?.error500);
    //     // console.log(e);
    //   });

    // UtilsService.getAllZipcode()
    //   .then((res) => {
    //     let zipcodeList = res.data.data.map(function (zipcode_id) {
    //       return { value: zipcode_id.id, label: zipcode_id.name };
    //     });
    //     // console.log(zipcodeList);
    //     setZipcodeList(zipcodeList);
    //   })
    //   .catch((e) => {
    //     navigate(routes?.error500);
    //     // console.log(e);
    //   });
  }, [setCityList, setCountryList, setProvinceList, setZipcodeList]);
  // ----------

  // SHOW IDENTITY FORMIK LOGIC WITH ONSUBMIT FUNCTION

  const formik = useFormik({
    initialValues: {
      user_identity: 'show',
      payment_type: 'online',
      category: 'website',

      user_identity: 'show',
      payment_type: 'online',
      money_donation_type_id: props.data?.money_donation_type_id ?? '',
      primary_contact_code_id: props.data?.primary_contact_code_id ?? 1,
      primary_contact_number: props.data?.primary_contact_number ?? '',
      first_name: props.data?.first_name ?? '',
      last_name: props.data?.last_name ?? '',
      email_id: props.data?.email_id ?? '',
      address_line_1: props.data?.address_line_1 ?? '',
      address_line_2: props.data?.address_line_2 ?? '',
      city_id: props.data?.city_id ?? '',
      country_id: props.data?.country_id ?? '',
      province_id: props.data?.province ?? '',
      zipcode_id: props.data?.zipcode_id ?? '',
      amount: props.data?.amount ?? '',
      category: 'website',
      is_80g_tax_opted: false,
      is_identity_hidden: '',
      pan_number: props.data?.pan_number ?? ''
      // primary_contact_code_id: data?.primary_contact_code_id ?? 0,
      // primary_contact_number: data?.primary_contact_number ?? "",
      // first_name: data?.first_name ?? "",
      // last_name: data?.last_name ?? "",
      // email_id: data?.email_id ?? "",
      // address_line_1: data?.address_line_1 ?? "",
      // address_line_2: data?.address_line_2 ?? "",
      // city_id: data?.city ?? "",
      // country_id: data?.country ?? "",
      // province_id: data?.province ?? "",
      // zipcode_id: data?.zipcode_id ?? "",
      // amount: data?.amount ?? "",
      // category_id: 10,
      // is_identity_hidden: "",
      // pan_card: data?.pan_card ?? "",
    },
    validationSchema: goldDonationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    validate,
    onSubmit: (values) => {
      // console.log(values);
      setProcessing(true);
      if (userData) {
        const requestData = {
          user_identity: 'show',
          payment_type: 'online',
          category: 'website',

          is_identity_hidden: false,
          amount: Number(values?.amount),
          //   category_id: values?.category_id,
          user_id: userData?.id,

          first_name: values?.first_name,
          last_name: values?.last_name,
          email_id: values?.email_id,
          primary_contact_code_id: 1,
          primary_contact_number: values?.primary_contact_number,
          secondary_contact_code_id: 1,
          secondary_contact_number: null,
          address_line_1: values?.address_line_1,
          address_line_2: values?.address_line_2,
          city_id: values?.city_id,
          country_id: values?.country_id,
          province_id: values?.province_id,
          zipcode_id: values?.zipcode_id,
          pan_card: values?.pan_card
        };
        GoldDonationService.checkoutGoldDonation(requestData)
          .then((res) => {
            if (res.data.success) {
              setProcessing(false);
              window.open(
                res.data.data?.short_url,
                '_blank',
                'rel=noopener noreferrer'
              );
              // navigate(res.data.data?.short_url)
            }
          })
          .catch((error) => {
            toast.error(error.response?.data?.message, { autoClose: 1500 });
            navigate(routes.moneyDonationFalied);
          });
      } else {
        const userData = {
          first_name: values?.first_name,
          last_name: values?.last_name,
          email_id: values?.email_id,
          primary_contact_code_id: 1,
          primary_contact_number: values?.primary_contact_number,
          secondary_contact_code_id: null,
          secondary_contact_number: null,
          address_line_1: values?.address_line_1,
          address_line_2: values?.address_line_2,
          city_id: values?.city_id,
          country_id: values?.country_id,
          province_id: values?.province_id,
          zipcode_id: values?.zipcode_id,
          pan_card: values?.pan_card,
          password: `${values?.first_name}@ambaji`,
          user_role_id: 1,
          user_status_id: 1
        };
        GoldDonationService.saveUser(userData).then((res) => {
          if (res.data.success) {
            const requestData = {
              // donation_gold: {
              is_identity_hidden: false,
              amount: Number(values?.amount),
              category_id: values?.category_id,
              user_id: res.data.data?.id,
              // },
              // donation_gold_details: {
              first_name: values?.first_name,
              last_name: values?.last_name,
              email_id: values?.email_id,
              primary_contact_code_id: 1,
              primary_contact_number: values?.primary_contact_number,
              secondary_contact_code_id: 1,
              secondary_contact_number: null,
              address_line_1: values?.address_line_1,
              address_line_2: values?.address_line_2,
              city_id: values?.city_id,
              country_id: values?.country_id,
              province_id: values?.province_id,
              zipcode_id: values?.zipcode_id,
              pan_card: values?.pan_card
              //*/
              // },
            };
            GoldDonationService.checkoutGoldDonation(requestData)
              .then((res) => {
                if (res.data.success) {
                  setProcessing(false);
                  window.open(
                    res.data.data?.short_url,
                    '_blank',
                    'rel=noopener noreferrer'
                  );
                  // navigate(res.data.data?.short_url)
                }
              })
              .catch((error) => {
                toast.error(error.response?.data?.message, { autoClose: 1500 });
                navigate(routes.moneyDonationFalied);
              });
          }
        });
      }
    }
  });
  // ----------
  // GET PROVINCE API INTEGRATION
  const getProvince = (event) => {
    UtilsService.getProvince(event?.target?.value ?? event)
      .then((res) => {
        if (res.data.success) {
          setProvinceList(res.data.data);
        }
      })
      .catch((error) => {
        navigate(routes?.error500);
        // console.log(error);
      });
  };

  // GET CITY API INTEGRATION
  const getCity = (event) => {
    UtilsService.getCity(event?.target?.value ?? event)
      .then((res) => {
        if (res.data.success) {
          setCityList(res.data.data);
        }
      })
      .catch((error) => {
        navigate(routes?.error500);
        // console.log(error)
      });
  };

  // GET ZIPCODE BY CITY API INTEGRATION

  const getZipcodeByCityDeliverable = (event) => {
    UtilsService.getZipcodeByCityDeliverable(event?.target?.value ?? event)
      .then((res) => {
        if (res.data.success) {
          setZipcodeList(res.data.data);
        }
      })
      .catch((error) => {
        navigate(routes?.error500);
        // console.log(error);
      });
  };
  // HIDE IDENTITY FORMIK LOGIC
  useEffect(() => {}, [formik?.values?.country_id]);
  // ----------
  const hideIndentityFormik = useFormik({
    initialValues: {
      amount: data?.amount ?? '',
      //   category_id: 10,
      category: 'website',
      user_identity: 'hidden',
      payment_type: 'online',
      is_identity_hidden: ''
    },
    validationSchema: goldDonationSchema,
    validate,
    onSubmit: (values) => {
      const requestData = {
        // donation_gold: {
        is_identity_hidden: true,
        amount: Number(values?.amount),
        category: 'website',
        user_identity: 'hidden',
        payment_type: 'online',
        user_id: userData?.id
        // },
      };
      GoldDonationService.checkoutGoldDonation(requestData)
        .then((res) => {
          if (res.data.success) {
            window.open(
              res.data.data?.short_url,
              '_self',
              'rel=noopener noreferrer'
            );
            // navigate(res.data.data?.short_url)
          }
        })
        .catch((error) => {
          toast.error(error.response?.data?.message, { autoClose: 1500 });
          navigate(routes.moneyDonationFalied);
        });
    }
  });
  // ----------

  // CHECK IF USER REGISTER OR NOT

  const checkForContactNumber = (event) => {
    if (formik?.values?.primary_contact_number) {
      GoldDonationService.checkRegisteredNumber(
        formik?.values?.primary_contact_number
      ).then((res) => {
        if (res.data.success) {
          setNumberRegistered(true);
          setNumberEditable(false);
          setUserData(res.data.data[0]);
          // console.log(res.data.data[0].UserAddresses);
          if (res.data.data[0].UserAddresses.length > 0) {
            setUserAddress(res.data.data[0].UserAddresses[0]);
          }
          GoldDonationService.sendOTP(formik?.values?.primary_contact_number)
            .then((res) => {
              if (res.data.success) {
                setOTPSend(true);
                setSeconds(30);
              }
            })
            .catch((error) => {
              toast.error(error.response?.data?.message, { autoClose: 1500 });
              // navigate(routes?.error500);
            });
        }
      });
    }
  };
  // ----------

  /**
   * Resend OTP
   * @param primary_contact_number
   */
  const resendOTP = () => {
    GoldDonationService.sendOTP(formik?.values?.primary_contact_number)
      .then((res) => {
        if (res.data.success) {
          setOTPSend(true);
          setSeconds(30);
        }
      })
      .catch((error) => {
        toast.error(error.response?.data?.message, { autoClose: 1500 });
        // navigate(routes?.error500);
      });
  };
  // ----------

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  /**
   * Verify OTP
   * @param primary_contact_number
   */
  const verifyOTP = () => {
    if (otp?.length === 6) {
      formik.setFieldValue('first_name', '');
      formik.setFieldValue('last_name', '');
      formik.setFieldValue('email_id', '');
      formik.setFieldValue('address_line_1', '');
      formik.setFieldValue('address_line_2', '');
      formik.setFieldValue('city_id', '');
      formik.setFieldValue('country_id', '');
      formik.setFieldValue('province_id', '');
      formik.setFieldValue('zipcode_id', '');
      formik.setFieldValue('pan_card', '');
      // formik?.resetForm()

      GoldDonationService.verifyOTP(formik?.values?.primary_contact_number, otp)
        .then((res) => {
          if (res.data.success) {
            if (userData) {
              setOpenModal(true);
            }
            setShowForm(true);
            // setOTPVerified(true);
          }
        })
        .catch((error) => {
          toast.error(error.response?.data?.message, { autoClose: 1500 });
          // navigate(routes?.error500);
        });
    }
  };
  // ----------

  // DATA BINDIG FOR USERDATA

  const bindData = () => {
    getProvince(userAddress.country_id);
    getCity(userAddress.province_id);
    getZipcodeByCityDeliverable(userAddress.city_id);
    formik.setFieldValue('first_name', userData?.first_name);
    formik.setFieldValue('last_name', userData?.last_name);
    formik.setFieldValue('email_id', userData?.email_id);
    formik.setFieldValue('address_line_1', userAddress?.address_line_1);
    formik.setFieldValue('address_line_2', userAddress?.address_line_2);
    formik.setFieldValue('city_id', userAddress?.city_id);
    // formik.setFieldValue("country_id", userAddress?.country_id ?? 1);
    // formik.setFieldValue("province_id", userAddress?.province_id ?? 11);
    formik.setFieldValue('country_id', userAddress?.country_id);
    formik.setFieldValue('province_id', userAddress?.province_id);
    formik.setFieldValue('zipcode_id', userAddress?.zipcode_id);
    formik.setFieldValue('pan_card', userData?.pan_number);
    let index = cityList.findIndex(
      (city) => city.value === userAddress?.city_id
    );
    setSelectedCity(index);
    setOpenModal(false);
  };
  // ----------

  const { handleChange, handleSubmit } = formik;

  // START RETURN

  return (
    <>
      <Helmet>
        <title>Ambaji Temple - Donate Gold</title>
        <meta charset="UTF-8" />
        <meta
          name="description"
          content="Donate Gold in Ambaji Temple can keep your identity Hidden and Visible."
        />
        <meta
          name="keywords"
          content="Donate Gold Ambaji Temple , Ambaji Temple Donate Gold , Donate Gold with Hidden Identity at Ambaji Temple, Donate Gold "
        />
        <meta name="author" content="BinTech Services" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>

      {!isLoading ? (
        <div className="main-wrapper">
          <section
            className="header-banner gold-banner"
            // style={{ backgroundImage: 'url("DonationGold.jpg")' }}
          >
            <div className="container">
              <h1>Donation - Gold</h1>
            </div>
          </section>
          <section className="gold-donation">
            <div className="container">
              <div className="row">
                <div className="col-xl-12">
                  <div className="card">
                    <div className="row">
                      <div className="col-lg-7">
                        <div className="donationform">
                          <div className="identity-option form-group">
                            <label>Donation Identity:</label>
                            <br />
                            <div
                              className="flexinner"
                              style={{ display: 'inline-block' }}
                            >
                              <div
                                className="show"
                                style={{
                                  cursor: 'pointer',
                                  marginBottom: '15px'
                                }}
                                onClick={() => [
                                  setIdentityHidden('show'),
                                  formik?.setFieldValue(
                                    'is_identity_hidden',
                                    false
                                  )
                                ]}
                              >
                                <input
                                  type="radio"
                                  value={false}
                                  name="is_identity_hidden"
                                  style={{ marginRight: '5px' }}
                                  checked={isIdentityHidden === 'show'}
                                  // onClick={() => setIdentityHidden("show")}
                                />
                                <span>Show My Identity (Enter Details)</span>
                              </div>

                              <div
                                className="hide"
                                style={{ cursor: 'pointer' }}
                                onClick={() => [
                                  setIdentityHidden('hide'),
                                  formik?.setFieldValue(
                                    'is_identity_hidden',
                                    true
                                  )
                                ]}
                              >
                                <input
                                  type="radio"
                                  value={true}
                                  name="is_identity_hidden"
                                  style={{ marginRight: '5px' }}
                                  checked={isIdentityHidden === 'hide'}
                                />{' '}
                                <span style={{ marginRight: '10px' }}>
                                  Hide My Identity (Guest Donation)
                                </span>{' '}
                              </div>
                              {/* <br /> */}
                            </div>
                          </div>
                          {isIdentityHidden === 'show' ? (
                            <form
                              onSubmit={formik.handleSubmit}
                              autoComplete="off"
                            >
                              <div className="row align-items-center">
                                <div className="form-group col-12 col-md-8">
                                  <div className="row">
                                    <div className="form-group col-4">
                                      <label htmlFor="primary_contact_code_id">
                                        Code / કોડ
                                      </label>
                                      <select
                                        name="primary_contact_code_id"
                                        id="primary_contact_code_id"
                                        className="form-control"
                                        disabled={!isNumberEditable}
                                      >
                                        <option value={0} disabled>
                                          Select
                                        </option>
                                        {phoneCodeList?.map((item, i) => {
                                          return (
                                            <option
                                              value={item?.id}
                                              selected={
                                                item?.phone_code == '91'
                                              }
                                              key={i}
                                            >
                                              +{item?.phone_code}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                    <div className="form-group col-8">
                                      <label>Mobile Number / મોબાઈલ નંબર</label>
                                      <input
                                        type="text"
                                        inputMode="numeric"
                                        className="form-control"
                                        name="primary_contact_number"
                                        value={
                                          formik?.values.primary_contact_number
                                        }
                                        onChange={handleChange}
                                        disabled={!isNumberEditable}
                                        required
                                      />
                                      {/* <p className="mb-1 red-text">
                                {formik?.errors?.primary_contact_number}
                              </p> */}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-8 form-group col-md-4">
                                  {isNumberEditable ? (
                                    <button
                                      type="button"
                                      className="whitebtn"
                                      onClick={() => checkForContactNumber()}
                                    >
                                      Verify Number
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      className="whitebtn"
                                      onClick={() => [
                                        setNumberEditable(true),
                                        setNumberRegistered(false),
                                        setShowForm(false)
                                      ]}
                                    >
                                      Edit Number
                                    </button>
                                  )}
                                </div>
                              </div>

                              {isNumberRegistered &&
                                !isNumberEditable &&
                                !isShowForm && (
                                  <div className="row align-items-center">
                                    <div className="col-md-5 col-6 form-group">
                                      <label htmlFor="otp">OTP</label>
                                      {/* <input
                              type="text"
                          inputMode="numeric"
                                name="otp"
                                id="otp"
                                min="6"
                                max="6"
                                className="form-control"
                                placeholder="_ _ _ _ _ _"
                                onKeyUp={(event) => setOTP(event.target.value)}
                              /> */}
                                      <input
                                        type="text"
                                        inputMode="numeric"
                                        minLength="6"
                                        maxLength="6"
                                        pattern="[0-9]*" // Only allow numeric input
                                        name="otp"
                                        id="otp"
                                        className="form-control"
                                        placeholder="_ _ _ _ _ _"
                                        value={otp}
                                        onChange={(event) => {
                                          const inputValue =
                                            event.target.value.replace(
                                              /\D/g,
                                              ''
                                            ); // Remove non-digit characters
                                          setOTP(inputValue.slice(0, 6)); // Limit to 6 digits and update state
                                          setResend(false); // Reset the resend state if needed
                                        }}
                                      />
                                    </div>
                                    <div className="col-md-7 col-12">
                                      <button
                                        type="button"
                                        className="orangebtn"
                                        onClick={() => verifyOTP()}
                                        disabled={otp?.length !== 6}
                                      >
                                        Verify OTP
                                      </button>
                                      {isOTPSend && seconds == '00' && (
                                        <button
                                          type="button"
                                          className="whitebtn ms-3"
                                          onClick={() => [
                                            resendOTP(),
                                            setOTP(''),
                                            setResend(true)
                                          ]}
                                        >
                                          Resend OTP
                                        </button>
                                      )}
                                    </div>
                                    {isOTPSend && seconds > 0 ? (
                                      <p>
                                        Resend OTP in{' '}
                                        {seconds < 10
                                          ? `0${seconds}`
                                          : ` ${seconds}`}{' '}
                                        sec
                                      </p>
                                    ) : (
                                      <p></p>
                                    )}
                                    {!isResend &&
                                      (otp?.length > 6 || otp?.length < 6) && (
                                        <p className="red-text">
                                          OTP must be a 6 digits number
                                        </p>
                                      )}
                                  </div>
                                )}

                              {isShowForm && !isNumberEditable ? (
                                <>
                                  <div className="row mt-3">
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>First Name / પ્રથમ નામ</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="first_name"
                                          value={formik?.values.first_name}
                                          onChange={handleChange}
                                          required
                                        />
                                        {/* {formik?.touched?.first_name &&
                                  formik?.errors?.first_name && (
                                    <p className="mb-1 red-text">
                                      {formik?.errors?.first_name}
                                    </p>
                                  )} */}
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>Last Name / અટક</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="last_name"
                                          value={formik?.values.last_name}
                                          onChange={handleChange}
                                          required
                                        />
                                        {/* {formik?.touched?.last_name &&
                                  formik?.errors?.last_name && (
                                    <p className="mb-1 red-text">
                                      {formik?.errors?.last_name}
                                    </p>
                                  )} */}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="form-group">
                                        <label>Email Id / ઈમેલ આઈડી</label>
                                        <input
                                          type="email"
                                          className="form-control"
                                          name="email_id"
                                          value={formik?.values.email_id}
                                          onChange={handleChange}
                                          required
                                        />
                                        {/* {formik?.touched?.email_id &&
                                  formik?.errors?.email_id && (
                                    <p className="mb-1 red-text">
                                      {formik?.errors?.email_id}
                                    </p>
                                  )} */}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-12">
                                      <div className="form-group">
                                        <label>
                                          PAN Number (Required to claim TAX) /
                                          પાન કાર્ડ નંબર
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="pan_card"
                                          value={formik?.values.pan_card}
                                          onChange={handleChange}
                                          style={{ textTransform: 'uppercase' }}
                                        />
                                        {/* {
                                  formik?.errors?.pan_card && (
                                    <p className="mb-1 red-text">
                                      {formik?.errors?.pan_card}
                                    </p>
                                  )} */}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>
                                          Address Line 1 / એડ્રેસ લાઇન 1
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="address_line_1"
                                          value={formik?.values.address_line_1}
                                          onChange={handleChange}
                                          required
                                        />
                                        {/* {formik?.touched?.address_line_1 &&
                                  formik?.errors?.address_line_1 && (
                                    <p className="mb-1 red-text">
                                      {formik?.errors?.address_line_1}
                                    </p>
                                  )} */}
                                      </div>
                                    </div>
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>
                                          Address Line 2 / એડ્રેસ લાઇન 2
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          name="address_line_2"
                                          value={formik?.values.address_line_2}
                                          onChange={handleChange}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    {/* COUNTRY FIELD  */}
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label htmlFor="country_id">
                                          Country/દેશ
                                          <span className="text-red">*</span>
                                        </label>
                                        <select
                                          name="country_id"
                                          className="form-control"
                                          value={formik?.values?.country_id}
                                          required
                                          onChange={(event) => [
                                            formik.setFieldValue(
                                              'country_id',
                                              event.target.value
                                            ),
                                            getProvince(event)
                                          ]}
                                        >
                                          <option value="" selected disabled>
                                            Select Country
                                          </option>
                                          {countryList?.map((item, i) => {
                                            return (
                                              <option value={item?.id}>
                                                {item?.name}
                                              </option>
                                            );
                                          })}
                                        </select>
                                        {/* {formik.errors.country &&
                                          formik.touched.country && (
                                            <div className="invalid-feedback">
                                              {formik.errors.country}
                                            </div>
                                          )} */}
                                      </div>
                                    </div>

                                    {/* PROVINCE FIELD  */}
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label>
                                          Province/રાજ્ય
                                          <span className="text-red">*</span>
                                        </label>
                                        <select
                                          name="province_id"
                                          className="form-control"
                                          required
                                          value={formik?.values?.province_id}
                                          onChange={(event) => [
                                            formik.setFieldValue(
                                              'province_id',
                                              event.target.value
                                            ),
                                            getCity(event)
                                          ]}
                                        >
                                          <option value="">Select State</option>
                                          {provinceList?.map((item, i) => {
                                            return (
                                              <option value={item?.id}>
                                                {item?.name}
                                              </option>
                                            );
                                          })}
                                        </select>
                                        {formik?.touched?.province_id &&
                                          formik?.errors?.province_id && (
                                            <p className="red-text">
                                              {formik?.errors?.province_id}
                                            </p>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    {/* CITY FIELD  */}
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label htmlFor="city_id">
                                          City/શહેર
                                          <span className="text-red">*</span>
                                        </label>
                                        <select
                                          name="city_id"
                                          className="form-control"
                                          required
                                          value={formik?.values?.city_id}
                                          onChange={(event) => [
                                            formik.setFieldValue(
                                              'city_id',
                                              event.target.value
                                            ),
                                            getZipcodeByCityDeliverable(event)
                                          ]}
                                          // onChange={formik.handleChange}
                                        >
                                          <option value="" selected disabled>
                                            Select City
                                          </option>
                                          {console.log(cityList)}
                                          {cityList?.map((item, i) => {
                                            return (
                                              <option value={item?.id}>
                                                {item?.name}
                                              </option>
                                            );
                                          })}
                                        </select>
                                        {formik?.touched?.city_id &&
                                          formik?.errors?.city_id && (
                                            <p className="red-text">
                                              {formik?.errors?.city_id}
                                            </p>
                                          )}
                                      </div>
                                    </div>

                                    {/* ZIPCODE FIELD  */}
                                    <div className="col-md-6">
                                      <div className="form-group">
                                        <label htmlFor="zipcode_id">
                                          Zipcode/પિનકોડ
                                          <span className="text-red">
                                            *
                                          </span>{' '}
                                          <small>
                                            (Below listed zipcodes are
                                            deliverable)
                                          </small>
                                        </label>
                                        <select
                                          name="zipcode_id"
                                          required
                                          className="form-control"
                                          value={
                                            formik?.values?.zipcode_id || ''
                                          }
                                          onChange={formik.handleChange}
                                        >
                                          <option value="" selected disabled>
                                            Select Zipcode
                                          </option>
                                          {zipcodeList?.map((item, i) => {
                                            return (
                                              <option
                                                key={item?.id}
                                                value={item?.id}
                                              >
                                                {item?.name}
                                              </option>
                                            );
                                          })}
                                        </select>
                                        {formik?.touched?.zipcode_id &&
                                          formik?.errors?.zipcode_id && (
                                            <p className="red-text">
                                              {formik?.errors?.zipcode_id}
                                            </p>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-group">
                                    <label>Amount / રકમ</label>
                                    <input
                                      type="text"
                                      inputMode="numeric"
                                      // className="form-control"
                                      className={`form-control ${
                                        formik.touched.amount &&
                                        formik.errors.amount
                                          ? 'is-invalid'
                                          : ''
                                      }`}
                                      name="amount"
                                      value={formik?.values.amount}
                                      // onChange={handleChange}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      onKeyDown={(e) => {
                                        // Allow only digits (0-9), backspace, and delete
                                        const allowedKeys = [
                                          '0',
                                          '1',
                                          '2',
                                          '3',
                                          '4',
                                          '5',
                                          '6',
                                          '7',
                                          '8',
                                          '9',
                                          'Backspace',
                                          'Delete'
                                        ];
                                        if (!allowedKeys.includes(e.key)) {
                                          e.preventDefault();
                                        }
                                      }}
                                    />
                                    {formik.touched.amount &&
                                      formik.errors.amount && (
                                        <p className="mb-1 red-text">
                                          {formik.errors.amount}
                                        </p>
                                      )}
                                  </div>

                                  <button
                                    className="orangebtn"
                                    type="submit"
                                    style={{ width: '100%' }}
                                    // disabled={!formik?.isValid}
                                    // onClick={handleNextButtonClick}
                                  >
                                    Donate Money for Gold
                                  </button>
                                </>
                              ) : null}
                            </form>
                          ) : isIdentityHidden === 'hide' ? (
                            <form onSubmit={hideIndentityFormik?.handleSubmit}>
                              <div className="form-group">
                                <label>Amount / રકમ</label>
                                <input
                                  type="text"
                                  inputMode="numeric"
                                  // className="form-control"
                                  className={`form-control ${
                                    hideIndentityFormik.touched.amount &&
                                    hideIndentityFormik.errors.amount
                                      ? 'is-invalid'
                                      : ''
                                  }`}
                                  name="amount"
                                  value={hideIndentityFormik?.values.amount}
                                  // onChange={handleChange}
                                  onChange={hideIndentityFormik.handleChange}
                                  onBlur={hideIndentityFormik.handleBlur}
                                  onKeyDown={(e) => {
                                    // Allow only digits (0-9), backspace, and delete
                                    const allowedKeys = [
                                      '0',
                                      '1',
                                      '2',
                                      '3',
                                      '4',
                                      '5',
                                      '6',
                                      '7',
                                      '8',
                                      '9',
                                      'Backspace',
                                      'Delete'
                                    ];
                                    if (!allowedKeys.includes(e.key)) {
                                      e.preventDefault();
                                    }
                                  }}
                                />
                                {hideIndentityFormik.touched.amount &&
                                  hideIndentityFormik.errors.amount && (
                                    <p className="mb-1 red-text">
                                      {hideIndentityFormik.errors.amount}
                                    </p>
                                  )}
                              </div>
                              <button
                                className="orangebtn"
                                type="submit"
                                style={{ width: '100%' }}
                                // disabled={!hideIndentityFormik.amount?.isValid}
                                // onClick={handleNextButtonClick}
                              >
                                Donate Money for Gold
                              </button>
                            </form>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-5 imgorder">
                        <div className="donateimg">
                          <img
                            src={ambajiTemple2}
                            alt="Ambaji Temple Banaskatha Gujarat"
                          ></img>
                          <div className="donateimg-inner">
                            <img
                              src={ambajiTemple3}
                              alt="Ambaji Temple Banaskatha Gujarat"
                            ></img>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <Arasuri />
          <Footer />

          <Modal
            isOpen={openModal}
            onRequestClose={() => setOpenModal(false)}
            style={customStyles}
            shouldCloseOnOverlayClick={false}
            contentLabel="Example Modal"
          >
            <h4>Use the following details as registred with us?</h4>
            <div className="row">
              <div className="col-4">
                <b>First Name:</b>{' '}
              </div>
              <div className="col-6">{userData?.first_name || '-'}</div>
              <div className="col-4">
                <b>Last Name:</b>
              </div>
              <div className="col-6">{userData?.last_name || '-'}</div>
              <div className="col-4">
                <b>Email Id:</b>
              </div>
              <div className="col-6">{userData?.email_id || '-'}</div>
              <div className="col-4">
                <b>PAN Number:</b>
              </div>
              <div className="col-6">{userData?.pan_number || '-'}</div>
              <div className="col-4">
                <b>Address Line 1:</b>
              </div>
              <div className="col-6">{userAddress?.address_line_1 || '-'}</div>
              <div className="col-4">
                <b>Address Line 2:</b>
              </div>
              <div className="col-6">{userAddress?.address_line_2 || '-'}</div>
              <div className="col-4">
                <b>Country:</b>
              </div>
              <div className="col-6">{userAddress?.Country?.name || '-'}</div>
              <div className="col-4">
                <b>Province:</b>
              </div>
              <div className="col-6">{userAddress?.Province?.name || '-'}</div>
              <div className="col-4">
                <b>City:</b>
              </div>
              <div className="col-6">{userAddress?.City?.name || '-'}</div>
              <div className="col-4">
                <b>Zipcode:</b>
              </div>
              <div className="col-6">{userAddress?.ZipCode?.name || '-'}</div>
            </div>
            <br />
            <div className="d-flex justify-content-between">
              <button
                type="button"
                className="whitebtn"
                onClick={() => setOpenModal(false)}
              >
                No
              </button>
              <button
                type="button"
                className="orangebtn"
                onClick={() => bindData()}
              >
                Yes
              </button>
            </div>
          </Modal>
        </div>
      ) : (
        <LazyLoader />
      )}
    </>
  );

  // END RETURN
};

export default DonationGold;
